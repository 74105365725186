import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useWeb3} from "@3rdweb/hooks";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from "@mui/material/CircularProgress";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const ListingModal = (props) => {
    const {open, handleClose, marketPlaceModule, selectedNft, contractAddress, getListings}= props;
    const [price, setPrice] = useState(0)
    const [priceError, setPriceError] = useState('')
    const [loading, setLoading] = useState(false)

    const create = async () => {
        let error = '';
        let priceToUse = 0;
        if(isNaN(price)){
            error = 'Price needs to be a number';
        } else {
            const numDecimalPlaces = price.split(".")[1].length || 0;
            if(numDecimalPlaces > 5){
                error = 'Too many decimal places.'
            }
            priceToUse = parseFloat(price);
            if(priceToUse <= 0.00001){
                error = 'Price needs to be greater than 0.00001'
            }
        }
        setPriceError(error)
        if(error === ''){
            setLoading(true)
            const data = {
                assetContractAddress: contractAddress,
                buyoutPricePerToken: priceToUse.toString(),//ethers.utils.parseUnits(`${priceToUse}`),
                currencyContractAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                listingDurationInSeconds: 60 * 60 * 24 * 365,
                quantity: 1,
                startTimeInSeconds: Math.floor(Date.now() / 1000),
                tokenId: parseInt(selectedNft.id._hex, 16).toString(),
            }
            await marketPlaceModule
                .direct.createListing(data)
                .then((tx) => {
                    getListings()
                    handleClose()
                    setLoading(false)
                    setPrice(0);
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                    alert('An error occurred. Please try again later')
                });
        }
    }

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <Box sx={style}>
                {loading ? <div style={{width: '100%', textAlign: 'center'}}>
                    <Typography sx={{fontSize: 24}} color="text.secondary" gutterBottom>
                       Processing
                    </Typography>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        You will be asked for two confirmations if this is your first time listing an item from this collection.
                    </Typography>
                    <CircularProgress/>
                </div> : <>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Create your listing
                    </Typography>
                    <TextField
                        label="Price"
                        variant="filled"
                        id="outlined-start-adornment"
                        sx={{m: 1, width: '25ch'}}
                        helperText={priceError}
                        error={priceError !== ''}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">ETH</InputAdornment>,
                        }}
                    />
                    <br/>
                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} style={{marginTop: '24px'}}>
                        <Grid item xs={6} style={{textAlign: 'center', padding: 0}}>
                            <Button onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center', padding: 0}}>
                            <Button variant='contained' onClick={create}>Save</Button>
                        </Grid>
                    </Grid>
                </>
                }
            </Box>
        </Fade>
    </Modal>
}

const ListingButton = (props) => {
    const {address} = useWeb3()

    const {selectedNft, marketPlaceModule, nftModule, getListings, contractAddress} = props;
    const [selectedMarketNft, setSelectedMarketNft] = useState()
    const [enableButton, setEnableButton] = useState(false)
    const [listingAllowed, setListingAllowed] = useState(false)
    const [showListingModal, setShowListingModal] = useState(false)

    useEffect(() => {
        setListingAllowed(false)
        ;(async () => {
            await nftModule
                .getOwned(address)
                .then(result => {
                    let allowed = false;
                    if (Array.isArray(result)) {
                        const nft = result.find((ownedNft) => `${parseInt(ownedNft.metadata.id,16)}` === `${parseInt(selectedNft.id,16)}`)
                        if (nft) {
                            allowed = true;
                        }
                    }
                    setListingAllowed(allowed)
                })
                .catch((error) => {
                    console.error(error)
                    alert("An error occurred. Please try again later")
                })
        })()
    }, [selectedNft, address])

    useEffect(() => {
        if (!selectedMarketNft || !selectedNft) return
        setEnableButton(true)
    }, [selectedMarketNft, selectedNft])

    const listItem = () => {
        setShowListingModal(true)
    }

    return <>
        <Button variant="contained" onClick={listingAllowed ? listItem: null} disabled={!listingAllowed}>List Item</Button>
    <ListingModal
        open={showListingModal}
        handleClose={()=>setShowListingModal(false)}
        marketPlaceModule={marketPlaceModule}
        contractAddress={contractAddress}
        selectedNft={selectedNft}
        getListings={getListings}
    />
    </>
}

export default ListingButton;