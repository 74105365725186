import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import {useNavigate} from "react-router-dom";

const WalletInfo = (props) => {
    const {address} = props;
    let navigate = useNavigate();


    const [open, setOpen] = React.useState(false);

    let formattedAddress = '';
    if (address) {
        formattedAddress = `${address.substring(0, 6)}...${address.substring(38)}`
    }

    const goToAccount = () => {
        navigate('/myaccount')
    }

    return <div
        style={{display: 'flex'}}
        className={'nav-link'}
    >
        <div         style={{cursor: 'pointer'}}
                     onClick={goToAccount}>
            {formattedAddress}
        </div>
        <ContentCopyIcon
            style={{cursor: 'pointer', marginLeft: '8px'}}
            fontSize='small'
            onClick={() => {
                navigator.clipboard.writeText(address)
                setOpen(true)
            }
            }
        />
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            message="Copied"
            action={<></>}
        />
    </div>
}
export default WalletInfo;