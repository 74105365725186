import Button from "@mui/material/Button";
import React, {useEffect, useState}  from "react";
import {getListingItem} from "../../../lib/utils/getListingItem";
import {LoadingModal} from "../../LoadingModal";
import {ethers} from "ethers";
import {client} from "../../../lib/sanityClient";

const PurchaseButton = (props)=>{
    const { isListed, selectedNft, listings, marketPlaceModule, collection}=props;
    const [selectedMarketNft, setSelectedMarketNft] = useState()
    const [enableButton, setEnableButton] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        if (!listings || isListed === 'false') return
            ;(async () => {
            const listing = getListingItem(listings, selectedNft, collection);
            setSelectedMarketNft(listing)
        })()
    }, [selectedNft, listings, isListed])

    useEffect(() => {
        if (!selectedMarketNft || !selectedNft) return

        setEnableButton(true)
    }, [selectedMarketNft, selectedNft])


    const confirmPurchase = () => {
        alert('Purchase successful')
    }

    const buyItem = async (
    ) => {
        setShowLoading(true)
        const listingID = ethers.BigNumber.from(selectedMarketNft.id);
        await marketPlaceModule
            .direct.buyoutListing(listingID, 1)
            .then(()=>{
                client.patch(collection._id).inc({volumeTraded: 1}).commit().catch(e => {
                    console.log(e)
                })
                setShowLoading(false)
                confirmPurchase()
                window.location.reload();
            })
            .catch((error) => {
                setShowLoading(false)
                console.error(error)
                if(error.code === 'INSUFFICIENT_FUNDS'){
                    alert('Insufficient funds')
                } else {
                    alert('An error occurred. Please try again later')
                }
            })
    }
    return <>
    <Button variant="contained" onClick={enableButton? buyItem: null}>Buy Now</Button>
    <LoadingModal
        open={showLoading}/>
    </>
}

export default PurchaseButton;