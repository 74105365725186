import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../components/Page'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import {SocialIcon} from "react-social-icons";

const initialFormState ={
    firstName:'',
    lastName:'',
    email:'',
    portfolio:'',
    comments:'',
}
const initialFormStateErrors ={
    firstName:{errors:false, feedbackText:'Required'},
    lastName:{errors:false, feedbackText:'Required'},
    email:{errors:false, feedbackText:'Required'},
    portfolio:{errors:false, feedbackText:'Required'},
    comments:{errors:false, feedbackText:'Required'},
}

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default function Contact() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formState, setFormState] = useState(JSON.parse(JSON.stringify(initialFormState)));
    const [formStateErrors, setFormStateErrors] = useState(JSON.parse(JSON.stringify(initialFormStateErrors)));

    const handleChange = (id, value) => {
        let newState = JSON.parse(JSON.stringify(formState));
        newState[id]=value;
        setFormState(newState)
    }

    const validateForm = ()=> {
        let formValid = true;

        const {firstName, lastName, comments, email}=formState;
        let newStateErrors = JSON.parse(JSON.stringify(formStateErrors));

        if(!firstName || firstName === ''){
            formValid = false;
            newStateErrors.firstName.errors = true;
        } else {
            newStateErrors.firstName.errors = false;
        }

        if(!lastName || lastName === ''){
            formValid = false;
            newStateErrors.lastName.errors = true;
        } else {
            newStateErrors.lastName.errors = false;
        }

        if(!comments || comments === ''){
            formValid = false;
            newStateErrors.comments.errors = true;
        } else {
            newStateErrors.comments.errors = false;
        }

        if(!email || email === ''){
            formValid = false;
            newStateErrors.email.errors = true;
        } else {
            newStateErrors.email.errors = false;
        }
        setFormStateErrors(newStateErrors);
        return formValid;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const formValid = validateForm();

        if(formValid){
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...formState })
            })
                .then(() => setFormSubmitted(true))
                .catch(() => setFormSubmitted(true));
        }
    };

    if(formSubmitted){
        return         <Page>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Thanks for your response!
                        </Typography>
                        <Typography
                            component="h6"
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            We will reach out shortly.
                        </Typography>
                    </Container>
                </Box>
            </main>
        </Page>
    }

    return (
        <Page>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Contact
                        </Typography>
                        <Typography
                            component="h6"
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Whether you want to onboard as an artist, have a suggestion for the site or a general question, reach out! We want to hear from you!
                        </Typography>
                    </Container>
                </Box>
                <div style={{width: '50%', margin: '0 auto'}}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        name="contact"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <TextField
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            error={formStateErrors.firstName.errors}
                            helperText={formStateErrors.firstName.errors ? formStateErrors.firstName.feedbackText : ''}
                            value={formState.firstName}
                            onChange={(e)=>handleChange('firstName', e.target.value)}
                        />
                        <TextField
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            error={formStateErrors.lastName.errors}
                            helperText={formStateErrors.lastName.errors ? formStateErrors.lastName.feedbackText : ''}
                            value={formState.lastName}
                            onChange={(e)=>handleChange('lastName', e.target.value)}
                        />
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            error={formStateErrors.email.errors}
                            helperText={formStateErrors.email.errors ? formStateErrors.email.feedbackText : ''}
                            value={formState.email}
                            onChange={(e)=>handleChange('email', e.target.value)}
                        />
                        <TextField
                            id="outline"
                            label="Portfolio"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <WorkIcon />
                                    </InputAdornment>
                                ),
                            }}
                            type="email"
                            error={formStateErrors.portfolio.errors}
                            helperText={formStateErrors.portfolio.errors ? formStateErrors.portfolio.feedbackText : ''}
                            value={formState.portfolio}
                            onChange={(e)=>handleChange('portfolio', e.target.value)}
                        />
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Comments"
                            multiline
                            maxRows={4}
                            error={formStateErrors.comments.errors}
                            helperText={formStateErrors.comments.errors ? formStateErrors.comments.feedbackText : ''}
                            value={formState.comments}
                            onChange={(e)=>handleChange('comments', e.target.value)}
                        />
                        <p hidden>
                            <label>Don’t fill this out if you’re human: <input name="bot-field" onChange={(e)=>handleChange('bot-field', e.target.value)}/></label>
                        </p>
                        <div style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                style={{margin: '0 auto'}}
                                type={"submit"}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                    <div style={{textAlign: 'center', marginTop: 24}}>
                        <Typography component="h4" variant={'h4'}>Or find us here:</Typography>
                        <div>
                            <IconButton aria-label="join our discord" href={'https://discord.gg/EBgHmJxR7J'} target={'_blank'}>
                                <SocialIcon url="https://discord.gg/EBgHmJxR7J" />
                            </IconButton>
                            <IconButton aria-label="join our discord" href={'https://www.instagram.com/artevo_original/'} target={'_blank'}>
                                <SocialIcon url="https://www.instagram.com/artevo_original/" />
                            </IconButton>
                            <IconButton aria-label="follow our twitter" href={'https://twitter.com/ArtEvo_Original'} target={'_blank'}>
                                <SocialIcon url="https://twitter.com/ArtEvo_Original" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </main>
        </Page>
    );
}
//export default About;