import React from 'react';
import './App.css';
import './index.css';
import Home from './pages/Home'
import HomePage from './pages/HomePage'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./pages/About";
import Account from "./pages/Account";
import EditAccount from "./pages/EditAccount";
import Artists from "./pages/Artists";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Market from "./pages/Marketplace";
import GiovanniSechi from "./pages/Artists/GiovanniSechi";
import GelaZautashvili from "./pages/Artists/GelaZautashvili";
import Mari from "./pages/Artists/Mari";
import JemalKukhalshvili from "./pages/Artists/JemalKukhalshvili";
import GiovanniSechiBio from "./pages/Artists/GiovanniSechi/Bio";
import Collection from "./pages/Collection";
import NFTPage from "./pages/NFTPage";

const AppContainer = ()=>{
  return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="faq" element={<FAQ />} />
            <Route path="collection/:id" element={<Collection />} />
            <Route path="collection/:id/:itemID" element={<NFTPage />} />
            <Route path="marketplace" element={<Market />} />
            <Route path="contact" element={<Contact />} />
            <Route path="myAccount" element={<Account />} />
            <Route path="myAccount/edit" element={<EditAccount />} />
            <Route path="account/:address" element={<Account />} />
            <Route path="about" element={<About />} />
            <Route path="artists">
              <Route index element={<Artists />} />
              <Route path="giovanni-sechi">
                <Route index element={<GiovanniSechi/>} />
                <Route path="bio" element={<GiovanniSechiBio/>} />
              </Route>
              <Route path="gela-zautashvili" element={<GelaZautashvili/>} />
              {/*
                <Route path="jemal-kukhalashvili" element={<JemalKukhalshvili/>} />
*/}
              <Route path="mari" element={<Mari/>} />
            </Route>
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default AppContainer;
