import React, {useEffect} from 'react';
import {Container, Navbar, Nav, NavDropdown, Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useWeb3} from '@3rdweb/hooks'
import {client} from '../../lib/sanityClient'
import WalletInfo from "../WalletInfo";
import Typography from "@mui/material/Typography";


const NavbarComponent = () => {
    const {address, connectWallet} = useWeb3()
    let navigate = useNavigate();

    useEffect(() => {
        if (!address) return
            ;
        (async () => {
            const userDoc = {
                _type: 'users',
                _id: address,
                userName: 'Unnamed',
                walletAddress: address,
            }

            await client.createIfNotExists(userDoc)
        })()
    }, [address])

    const handleLocationClick = (location) => {
        navigate(location)
    }

    return (
        <Navbar collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand onClick={() => handleLocationClick('/')} style={{cursor: 'pointer'}}>
                    <img
                        src="/artEvoLogo.png"
                        height="45"
                        className="d-inline-block align-top"
                        alt="ArtEvo logo"
                    />
                    <Typography variant={'h6'} style={{fontSize: '1.1rem'}}>Art Evolution</Typography>
                </Navbar.Brand>
                {/*
            <Navbar.Brand onClick={()=>handleLocationClick('/')} style={{cursor: 'pointer'}}>ArtEvo {props.account}</Navbar.Brand>
*/}
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/*<Nav.Link onClick={()=>handleLocationClick('/')}>Home</Nav.Link>*/}
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={() => handleLocationClick('/marketplace')}>Marketplace</Nav.Link>
                        <NavDropdown title="Artists" id="collasible-nav-dropdown">
                            <NavDropdown.Item onClick={() => handleLocationClick('/artists/giovanni-sechi')}>Giovanni
                                Sechi</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleLocationClick('/artists/gela-zautashvili')}>Gela
                                Zautashvili</NavDropdown.Item>
                            {/*
                        <NavDropdown.Item onClick={()=>handleLocationClick('/artists/jemal-kukhalashvili')}>Jemal Kukhalashvili</NavDropdown.Item>
*/}
                            <NavDropdown.Item onClick={() => handleLocationClick('/artists/mari')}>Mari
                                Ataneli</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={() => handleLocationClick('/artists')}>All
                                artists</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => handleLocationClick('/contact')}>Contact</Nav.Link>
                        <Nav.Link onClick={() => handleLocationClick('/about')}>About</Nav.Link>
                        <Nav.Link onClick={() => handleLocationClick('/faq')}>FAQ</Nav.Link>
                        {address ? <Nav.Item>
                                <WalletInfo address={address}/>
                            </Nav.Item> :
                            <Nav.Item>
                                <Button
                                    onClick={() => connectWallet('injected')}
                                >
                                    Connect Wallet
                                </Button>
                            </Nav.Item>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default NavbarComponent;
