import { ThirdwebWeb3Provider } from "@3rdweb/hooks";
import AppContainer from "./AppContainer";

const App = ({ children }) => {
    // Put the ethereum chain ids of the chains you want to support
    const supportedChainIds = [4];

    /**
     * Include the connectors you want to support
     * injected - MetaMask
     * magic - Magic Link
     * walletconnect - Wallet Connect
     * walletlink - Coinbase Wallet
     */
    const connectors = {
        injected: {},
/*        magic: {
            apiKey: "pk_...", // Your magic api key
            chainId: 1, // The chain ID you want to allow on magic
        },*/
        walletconnect: {},
        walletlink: {
            appName: "ArtEvo - demo",
            url: "https://ArtEvo.uk",
            darkMode: false,
        },
    };

    /**
     * Make sure that your app is wrapped with these contexts.
     * If you're using Next JS, you'll have to replace children with the Component setup
     */
    return (
        <ThirdwebWeb3Provider
            connectors={connectors}
            supportedChainIds={supportedChainIds}
        >
            <AppContainer />
        </ThirdwebWeb3Provider>
    );
};

export default App;