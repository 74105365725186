import React, {useEffect, useState, useMemo} from 'react'
import {useWeb3} from '@3rdweb/hooks'
import moment from 'moment'
import {client} from '../../lib/sanityClient'

import {ethers} from "ethers";
import {ThirdwebSDK as ThirdwebSDKNightly} from "@thirdweb-dev/sdk";
import Page from '../../components/Page'

import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import NFTCard from "../../components/NFTCard";
import {mode} from "../../lib/constants";
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate, useParams} from "react-router-dom";



const Account = () => {
    const {address, provider} = useWeb3()
    let navigate = useNavigate();
    const [user, setUser] = useState(null)
    const [nfts, setNfts] = useState([])
    const [collections, setCollections] = useState([])
    const [loading, setLoading] = useState(true)

    const addressToUse = useParams().address || address


    useMemo(async () => {
        const nftsTemp = [];
        let sdk;
        if (!provider) {
            const provider2 =  ethers.getDefaultProvider(mode === "dev" ? 'rinkeby':'homestead');
            sdk = new ThirdwebSDKNightly(provider2);
        } else {
            sdk = new ThirdwebSDKNightly(provider);
        }
        for(const c in collections){
            const collectionTemp = sdk.getNFTCollection(collections[c].contractAddress);
            const nftsT = await collectionTemp.getOwned(addressToUse);
            nftsT.map(r => {
                const newNFT = r.metadata;
                newNFT.owner = r.owner;
                newNFT.collection = collections[c];
                nftsTemp.push(newNFT)
            })
        }
        setNfts(nftsTemp)
    }, [collections, addressToUse])


    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "marketItems"] {
      "imageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      volumeTraded,
      createdBy,
      contractAddress,
      "creator": createdBy->userName,
      title, floorPrice,
      "allOwners": owners[]->,
      description
    }`

        const collectionData = await sanityClient.fetch(query)
        setLoading(false)
        await setCollections(collectionData)
    }

    useEffect(() => {
        fetchCollectionData()
    }, [])

    useEffect(() => {
        if (!addressToUse) return
            ;
        (async () => {
            setUser(null)
            const query = `*[_type == "users" && _id == "${addressToUse}"] {
      "profileImageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      twitterHandle,
      _id,
      _createdAt,
      igHandle,
      walletAddress,
      userName
    }`

            client.fetch(query).then(users => {
                if(users.length>0){
                    setUser(users[0])
                }
                setLoading(false)
            }).catch(e => {
                setLoading(false)
                console.log(e)
            })
        })()
    }, [addressToUse])

    if (!addressToUse && !loading) {
        return <Page>
            <div style={{padding: '10vh'}}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Connect Wallet To View Account
                </Typography>
            </div>
        </Page>
    }

    return (
        <Page>
            {loading ? <div style={{width: '100%', textAlign: 'center'}}><CircularProgress/></div> :
                <>
                    {user ? <>
                            <div style={{
                                height: '30vh',
                                width: '100vw',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                display: 'flex',
                            }}>
                                <img
                                    style={{width: '100vw', objectFit: 'cover'}}
                                    src={
                                        user?.bannerImageUrl
                                            ? user.bannerImageUrl
                                            : 'https://via.placeholder.com/200'
                                    }
                                    alt="banner"
                                />
                            </div>
                            <div style={{marginTop: '-5%'}}>
                                <Avatar
                                    alt="Collection profile"
                                    style={{margin: '0 auto'}}
                                    src={user?.profileImageUrl
                                        ? user.profileImageUrl
                                        : 'https://via.placeholder.com/200'}
                                    sx={{width: 150, height: 150}}
                                />
                            </div>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="text.primary"
                            >
                                {user.userName}
                                {
                                    address && addressToUse=== address && <EditIcon
                                    onClick={()=>{
                                    navigate('/myAccount/edit')}
                                    }
                                        style={{cursor: 'pointer'}}
                                            fontSize="large"/>
                                }
                            </Typography>
                            <Typography
                                component="h5"
                                variant="h5"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                Joined {moment(user._createdAt).format('MMMM YYYY')}
                            </Typography>
                            <Container sx={{py: 8}} maxWidth="xl">
                                <Typography
                                    style={{marginBottom: '16px'}}
                                    component="h3"
                                    variant="h3"
                                    color="text.primary"
                                >
                                    Collection
                                </Typography>
                                <Grid container spacing={4}>
                                    {nfts.map((nft, index) => (
                                        <NFTCard nft={nft} key={index} collection={nft.collection} listings={[]}/>
                                    ))}
                                </Grid>
                            </Container>
                        </> :
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            User not found
                        </Typography>
                    }
                </>
            }
        </Page>
    )
}

export default Account