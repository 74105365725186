import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../components/Page'
import {client} from "../../lib/sanityClient";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Card from "@mui/material/Card";
import {CardActionArea} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

export default function Marketplace() {
    const [collections, setCollections] = useState([])
    const [loading, setLoading] = useState(true)
    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "marketItems"] {
      "imageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      volumeTraded,
      createdBy,
      contractAddress,
      "creator": createdBy->userName,
      title, floorPrice,
      "allOwners": owners[]->,
      description
    }`

        const collectionData = await sanityClient.fetch(query)
        setLoading(false)
        await setCollections(collectionData)
    }

    useEffect(() => {
        fetchCollectionData()
    }, [])

    if(loading){
        return <Page>
            <div style={{width: '100%', textAlign: 'center'}}>
                <CircularProgress/>
            </div>
        </Page>
    }
    return (
        <Page>
            <main>
                <div className="bg-light p-5 rounded-lg m-3">
                    <h1 className="display-4">ArtEvo Marketplace</h1>
                    <p className="lead">Save on fees by skipping third party markets like OpenSea!
                        We work directly with the artists so there is no seller fee or commission when selling art from one of our artists on our marketplace!
                    </p>
                </div>
                <Container sx={{ py: 8 }} maxWidth="xl">
                    <Grid container spacing={4}>
                        {collections.map((collection, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                <Link underline='none' component={RouterLink} to={`/collection/${collection.contractAddress}`}>
                                    <Card
                                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="300"
                                                image={collection.imageUrl}
                                                alt="random"
                                            />
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {collection.title}
                                                </Typography>
                                                <Typography gutterBottom variant="h6" component="h4">
                                                    {collection.description}
                                                </Typography>
                                                <Typography>
                                                    {collection.creator}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
        </Page>
    );
}
//export default About;