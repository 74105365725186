import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {getListingItem} from "../../lib/utils/getListingItem";
import AssetViewModal from "../AssetViewModal";



export default function NFTCard(props) {
    let navigate = useNavigate();

    const {key, collection, nft, listings}=props;
    const [isListed, setIsListed] = useState(false)
    const [price, setPrice] = useState(0)
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetToShow, setAssetToShow] = useState({});

    useEffect(() => {
        const listing = getListingItem(listings, nft, collection);
        if (Boolean(listing)) {
            setIsListed(true)
            setPrice(listing.buyoutCurrencyValuePerToken.displayValue)
        }
    }, [listings, nft])


    const handleLocationClick = () => {
        navigate(`/collection/${collection.contractAddress}/${nft.id}`)
    }

    const buyItem = ()=>{
        handleLocationClick();
    }

    return (
        <Grid item key={key} xs={12} sm={6} md={4}>
                <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                        <CardMedia
                            onClick={()=>{
                                setAssetToShow({name: nft.name, image:nft.image })
                                setShowAssetModal(true)
                            }}
                            component="img"
                            height="300"
                            style={{objectFit: 'contain', cursor: 'pointer'}}
                            image={nft.image}//nft.image.replace('cloudflare-ipfs.com', 'ipfs.thirdweb.com')
                            alt={nft.name}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} md={10}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {nft.name}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="h4">
                                            {nft.description}
                                        </Typography>
                                    </Grid>
                                    {isListed &&
                                        <Grid item xs={3} md={2}>
                                            <IconButton aria-label="buy now" onClick={buyItem}>
                                                <ShoppingCartIcon fontSize="large" />
                                            </IconButton>
                                    </Grid>}
                                </Grid>
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} md={8}>
                                        <Button variant="contained" size="small" onClick={handleLocationClick}>View</Button>
                                    </Grid>
                                    {isListed &&
                                        <Grid item xs={4} md={4}>
                                            <Typography gutterBottom variant="p" component="p" style={{textAlign: 'right'}}>
                                                {Math.round((parseFloat(price) + Number.EPSILON) * 100000) / 100000} ETH
                                            </Typography>
                                    </Grid>}
                                </Grid>
                            </Box>
                        </CardContent>
                </Card>
            <AssetViewModal open={showAssetModal} handleClose={()=>setShowAssetModal(false)} asset={assetToShow.image} assetName={assetToShow.name}/>
        </Grid>
    )
}