import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../../components/Page'

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import cool from '../../../assets/artists/gela_zautashvili/Cool Company.jpg';
import kali from '../../../assets/artists/gela_zautashvili/Kali Mandala.jpg';
import giant from '../../../assets/artists/gela_zautashvili/Giant Rainbow, Buddha and People.jpg';
import rigveda from '../../../assets/artists/gela_zautashvili/Rigveda.jpg';
import bellodego from '../../../assets/artists/gela_zautashvili/Bellodego.jpg';
import complicated from '../../../assets/artists/gela_zautashvili/The Complicated Questions.jpg';
import couples from '../../../assets/artists/gela_zautashvili/Couples.jpg';
import hello from '../../../assets/artists/gela_zautashvili/Hello Dolly.jpg';
import makeLove from '../../../assets/artists/gela_zautashvili/Make Love not War.jpg';
import tantra from '../../../assets/artists/gela_zautashvili/Tantra Story.jpg';
import shahe from '../../../assets/artists/gela_zautashvili/Shaherezada N3.jpg';
import banaras from '../../../assets/artists/gela_zautashvili/Banaras.jpg';


import reds from '../../../assets/artists/gela_zautashvili/Red_s Powers.jpg'
import lybido from '../../../assets/artists/gela_zautashvili/Lybido.jpg'
import Process from '../../../assets/artists/gela_zautashvili/Process.jpg'

import wales from '../../../assets/artists/gela_zautashvili/100wales'
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import AssetViewModal from "../../../components/AssetViewModal";
import {isMobile} from "react-device-detect";
import {Carousel} from "react-bootstrap";
import {Button} from "@mui/material";

const cards2 = [
    {
        "name": "Red's Powers",
        "bio": "",
        "image": reds
    },
    {
        "name": "Lybido",
        "bio": "",
        "image": lybido
    },
    {
        "name": "Process",
        "bio": "",
        "image": Process
    },
]


const cardsOld = [
    {
        "name": "Cool company",
        "bio": "",
        "image": cool
    },
    {
        "name": "Kali Mandala",
        "bio": "",
        "image": kali
    },
    {
        "name": "Giant rainbow , Buddha and people",
        "bio": "",
        "image": giant
    },
    {
        "name": "Rigveda",
        "bio": "",
        "image": rigveda
    },
    {
        "name": "Bellodego",
        "bio": "",
        "image": bellodego
    },
    {
        "name": "The complicated questions",
        "bio": "",
        "image": complicated
    },
    {
        "name": "Couples",
        "bio": "",
        "image": couples
    },
    {
        "name": " Hello Dolly",
        "bio": "",
        "image": hello
    },
    {
        "name": "Make Love not War",
        "bio": "",
        "image": makeLove
    },
    {
        "name": "Tantra story",
        "bio": "",
        "image": tantra
    },
    {
        "name": "Shaherezada N3",
        "bio": "",
        "image": shahe
    },
    {
        "name": "Banaras",
        "bio": "",
        "image": banaras
    }
];
const cards = [
    {
        "name": "Cool company",
        "bio": "",
        "image": cool
    },
    {
        "name": "Kali Mandala",
        "bio": "",
        "image": kali
    },
    {
        "name": "Couples",
        "bio": "",
        "image": couples
    },
    {
        "name": " Hello Dolly",
        "bio": "",
        "image": hello
    }
];

const walesCards = [
    {
        "name": "Akira Kurosava",
        "bio": "",
        "image": wales.akiraKurosava
    },
    {
        "name": "Albert Camus",
        "bio": "",
        "image": wales.albertCamus
    },
    {
        "name": "Andy Warhol",
        "bio": "",
        "image": wales.andyWarhol
    },
    {
        "name": "Federico Fellini",
        "bio": "",
        "image": wales.federicoFellini
    },
    {
        "name": "Franz Kafka",
        "bio": "",
        "image": wales.franzKafka
    },
    {
        "name": "G. Gurjieff",
        "bio": "",
        "image": wales.gGurjieff
    },
    {
        "name": "Henry Miller",
        "bio": "",
        "image": wales.henryMiller
    },
    {
        "name": "Herman Hesse",
        "bio": "",
        "image": wales.hermanHesse
    },
    {
        "name": "J. D. Salinger",
        "bio": "",
        "image": wales.jdSalinger
    },
    {
        "name": "James Joyce",
        "bio": "",
        "image": wales.jamesJoyce
    },
    {
        "name": "Josef Beuys",
        "bio": "",
        "image": wales.josefBeuys
    },
    {
        "name": "Luis Bunuel",
        "bio": "",
        "image": wales.luisBunuel
    },
    {
        "name": "Marcel Duchamp",
        "bio": "",
        "image": wales.marcelDuchamp
    },
    {
        "name": "Marcel Proust",
        "bio": "",
        "image": wales.marcelProust
    },
    {
        "name": "Miles Davis",
        "bio": "",
        "image": wales.milesDavis
    },
    {
        "name": "Pier Paolo Pasolini",
        "bio": "",
        "image": wales.pierPaoloPasolini
    },
    {
        "name": "Sigmund Freud",
        "bio": "",
        "image": wales.sigmundFreud
    },
    {
        "name": "Thomas Eliot",
        "bio": "",
        "image": wales.thomasEliot
    },
]

const lifeEvents = [
    {
        "year": "2011",
        "title": "Gala Gallery, Solo exhibition.",
        "desc": "Tbilisi, Georgia"
    },
    {
        "year": "2008",
        "title": "Gallery “Kopala”, Solo Exhibition “10+1” within Art Fair “Artisterium”.",
        "desc": "Tbilisi, Georgia"
    },
    {
        "year": "2004",
        "title": "Trade Center, Solo Exhibition.",
        "desc": "Linz, Austria"
    },
    {
        "year": "2003",
        "title": "Modern Art Gallery, Solo Exhibition. Tbilisi, Georgia 1999 Cultural Center, Solo Exhibition.",
        "desc": "Oftering, Austria"
    },
    {
        "year": "1998",
        "title": "Cultural Center, “Guru and Students”.",
        "desc": " Kungsbacka, Sweden"
    },
    {
        "year": "1992",
        "title": "New Gallery, “Choice and Value”.",
        "desc": "Tbilisi, Georgia"
    },
    {
        "year": "1991",
        "title": "“Red and White”.",
        "desc": "Warsaw, Poland"
    },
    {
        "year": "1991",
        "title": "“1970-1980 Avant-garde of the USSR”.",
        "desc": "Vazarelli Fund, France"
    },
    {
        "year": "1991",
        "title": "International Art Festival.",
        "desc": "Chicago,USA"
    },
    {
        "year": "1991",
        "title": "Exhibition of USSR Artists.",
        "desc": "Brussels, Belgium"
    },
    {
        "year": "1990",
        "title": "",
        "desc": ""
    },
    {
        "year": "1989",
        "title": " “USSR Avant–garde in Australia”.",
        "desc": "Adelaide, Australia "
    },
    {
        "year": "1989",
        "title": "Musical Culture Museum, Solo Exhibition",
        "desc": "Moscow, Russia"
    },
    {
        "year": "1989",
        "title": "Bodenschatz Gallery, An Exhibition of 3 USSR Artists.",
        "desc": "Basel, Switzerland"
    },
    {
        "year": "1989",
        "title": "Cultural Center, “Concept Plastic Idea”",
        "desc": "Moscow, Russia"
    },
    {
        "year": "1988",
        "title": "Karvasla, “Avant-garde 88”",
        "desc": "Tbilisi, Georgia"
    },
    {
        "year": "1987",
        "title": "National Gallery, Georgian Abstractionists.",
        "desc": "Tbilisi, Georgia"
    },
    {
        "year": "1987",
        "title": "7 Union “Ermitage”, Georgian Abstract Art.",
        "desc": "Moscow, Russia"
    }
]

export default function GelaZautashvili() {
    let navigate = useNavigate();
    const [currentPiece, setCurrentPiece] = useState(0);
    const [currentPiece2, setCurrentPiece2] = useState(0);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetToShow, setAssetToShow] = useState('');
    return (
        <Page>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Gela Zautashvili
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            “In the 21 century, when it is so popular to manipulate words and notions, I too dare to
                            have
                            a game of
                            my own and to call my art EZOTEROTIKS, based on two of my main interests-ezotericism
                            and erotica. Based on these two factors, the time we live in is totally Show Time and the so
                            called New Art is the most vivid example of this“
                            <br/>
                            <br/>
                            <br/>
                            His works are preserved in the collection of Moscow State Tretyakov Gallery of Russian Art,
                            the collection of Moscow Modern Art Center, the Hermann Hesse House Museum (in
                            Montagnola, Switzerland), the collection of Georgian Modern Art Museum and a number of
                            private collections in different countries.
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{py: 8}} maxWidth="lg">
                    <Typography
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Works
                    </Typography>
                    <Grid className='artist-card' md={6} xs={12} style={{
                        margin: '0 auto',
                        width: '90%',
                        maxWidth: isMobile ? '90%' : '70%',
                        height: isMobile ? '55vh' : ''
                    }}>
                        <Carousel onSelect={(s) => {
                            setCurrentPiece(s)
                        }}>
                            {cards.map(item =>
                                <Carousel.Item onClick={() => {
                                    setShowAssetModal(true)
                                    setAssetToShow(cards[currentPiece])
                                }}>
                                    <div style={{backgroundColor: 'lightgray'}}>
                                        <img
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            className="artist-card-image"
                                            src={item.image}
                                            alt={`${item.title} art`}
                                        />
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className='artist-card-info' style={{height: isMobile ? '10vh' : ''}}>
                            <Typography variant="p" component="p" style={{color: 'white'}}>
                                {cards[currentPiece].name}
                            </Typography>
                            {cards[currentPiece].showLink &&
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(cards[currentPiece].link)}>View In
                                    Market</Button>
                            }
                        </div>
                    </Grid>
                    <Typography
                        style={{
                            marginTop: 30
                        }}
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        100 Wales
                    </Typography>
                    <Grid className='artist-card' md={6} xs={12} style={{
                        margin: '0 auto',
                        width: '90%',
                        maxWidth: isMobile ? '90%' : '70%',
                        height: isMobile ? '55vh' : ''
                    }}>
                        <Carousel onSelect={(s) => {
                            setCurrentPiece2(s)
                        }}>
                            {walesCards.map(item =>
                                <Carousel.Item onClick={() => {
                                    setShowAssetModal(true)
                                    setAssetToShow(walesCards[currentPiece2])
                                }}>
                                    <div style={{backgroundColor: 'lightgray'}}>
                                        <img
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            className="artist-card-image"
                                            src={item.image}
                                            alt={`${item.title} art`}
                                        />
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className='artist-card-info' style={{height: isMobile ? '10vh' : ''}}>
                            <Typography variant="p" component="p" style={{color: 'white'}}>
                                {walesCards[currentPiece2].name}
                            </Typography>
                            {walesCards[currentPiece2].showLink &&
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(walesCards[currentPiece2].link)}>View In
                                    Market</Button>}
                        </div>
                    </Grid>
                    <Typography
                        style={{paddingTop: '24px'}}
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Exhibits
                    </Typography>
                    <Timeline position="alternate">
                        {lifeEvents.map(event =>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {event.year}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <strong>{event.title}</strong> {event.desc}
                                </TimelineContent>
                            </TimelineItem>
                        )}
                    </Timeline>
                </Container>
            </main>
            <AssetViewModal open={showAssetModal} handleClose={() => setShowAssetModal(false)} asset={assetToShow.image}
                            assetName={assetToShow.name}/>
        </Page>
    );
}
//export default About;