import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {Button, Typography} from "@mui/material";
import {isMobile} from "react-device-detect";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px',
    border: 'none !important'
};
const AssetViewModal = (props) => {
    const {open, handleClose, asset, assetName} = props;
    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style} style={{width: isMobile? '90%' :''}}>
            <Typography variant={'h4'} style={{textAlign: 'center'}}>{assetName}</Typography>
            <img
                style={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '100%'
                }}
                src={asset}
                alt={`art piece`}
            />
            <div style={{textAlign: 'center', marginTop: '10px'}}>
                <Button onClick={handleClose} variant="contained"
                >Close</Button></div>
        </Box>
    </Modal>
}

export default AssetViewModal;