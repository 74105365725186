// Dev
export const alchemy = {
    dev: {
        key: 'UjmV-vj3eTsE-3vWgNoyaLDxxnKbIsbI',
        httpKey: 'https://eth-rinkeby.alchemyapi.io/v2/UjmV-vj3eTsE-3vWgNoyaLDxxnKbIsbI',
    },
    prod: {
        key: 'doywSlOR_aczNp4243j7EDQgMyKTcAle',
        httpKey: 'https://eth-mainnet.alchemyapi.io/v2/doywSlOR_aczNp4243j7EDQgMyKTcAle',
    }
}