import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const LoadingModal = (props) => {
    const {open, handleClose = ()=>{}, text='Processing'}= props;

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <Box sx={style}>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Typography sx={{fontSize: 24}} color="text.secondary" gutterBottom>
                        {text}
                    </Typography>
                    <CircularProgress/>
                </div>
            </Box>
        </Fade>
    </Modal>
}

export {LoadingModal}
