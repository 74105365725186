import React, {useEffect, useState} from 'react';
import Page from '../../components/Page'
import bg from "../../assets/bg2.jpg";
import bgMobile from "../../assets/bg3.jpg";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Carousel} from "react-bootstrap";
import {Parallax} from "react-parallax";


import './HomeStyle.css'
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import giovannisechi from "../../assets/artists/giovannisechi/Monitors.jpg";
import gela_Zautashvili  from "../../assets/artists/gela_zautashvili/Couples.jpg";
import mari from "../../assets/artists/mari/IMG_3908 - Copy - Copy.jpg";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import mural from "../../assets/mural2.jpg";
import {isMobile} from 'react-device-detect';


const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

const artists = [
    {
        img: giovannisechi,
        title: 'Giovanni Sechi',
        link: "/artists/giovanni-sechi"
    },
    {
        img: gela_Zautashvili ,
        title: 'Gela Zautashvili ',
        link: '/artists/gela-zautashvili',
    },
    {
        img: mari,
        title: 'Mari Ataneli',
        link: '/artists/mari'
    },
];

const HomePage = () => {
    let navigate = useNavigate();

    const [currentArtist, setCurrentArtist] = useState(Math.floor(Math.random() * artists.length));

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    console.log(height)

    const ratio = width/height;
    console.log(ratio)

    return <Page>
        <div>
            {width < 950 || isMobile ? <div className='bg-image' style={{
                backgroundImage: `url(${bgMobile})`,
                filter: 'none',
                '-webkit-filter': 'none',
                height: '110vh'
            }}>
                <Stack spacing={2} style={{filter: 'none !important'}}>
                    <div style={{marginTop: '200px'}}>
                        <div className="vertical-center-container">
                            <div className="vertical-center">
                                <Stack spacing={2} style={{textAlign: 'left', padding: '5%'}}>
                                    <Typography variant="h1" component="h1" style={{color: 'white'}}>
                                        ArtEvo
                                    </Typography>
                                    <Typography variant="p" component="p" style={{color: 'white'}}>
                                        ArtEvo is a boutique NFT gallery working with handpicked artists to deliver
                                        unique digital experiences
                                    </Typography>
                                    <Grid container spacing={2} className='button-container'
                                          style={{width: '50%', margin: '0 auto'}}
                                          rowSpacing={1}
                                    >
                                        <Grid item sm={6} xs={12} className='no-padding'
                                              style={{marginTop: '10px', paddingRight: '5px'}}>
                                            <Button
                                                style={{boxShadow: 'none'}}
                                                size={'large'}
                                                variant="contained"
                                                onClick={() => navigate('/marketplace')}>Explore</Button>
                                        </Grid>
                                        <Grid item sm={6} xs={12} className='no-padding'
                                              style={{marginTop: '10px', paddingLeft: '5px'}}>
                                            <Button
                                                size={'large'}
                                                variant="contained"
                                                className='dark-button'
                                                onClick={() => navigate('/contact')}
                                            >Contact</Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '200px'}}>
                        <Grid className='artist-card' md={5} xs={12} style={{margin: '0 auto', width: '90%', height: '55vh'}}>
                            <Carousel onSelect={(s) => {
                                setCurrentArtist(s)
                            }}>
                                {artists.map((item, i) =>
                                    <Carousel.Item key={`image-${i}`}>
                                        <img
                                            className="artist-card-image"
                                            src={item.img}
                                            alt={`${item.title} art`}
                                        />
                                    </Carousel.Item>
                                )}
                            </Carousel>
                            <div className='artist-card-info' style={{height: '10vh'}}>
                                <Typography variant="p" component="p" style={{color: 'white'}}>
                                    {artists[currentArtist].title}
                                </Typography>
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(artists[currentArtist].link)}>View
                                    Works</Button>
                            </div>
                        </Grid>
                    </div>
                </Stack>
            </div> : <>
                <Parallax blur={6} bgImage={bg} strength={100}>
                    <div style={{height: '100vh'}}>
                    </div>
                </Parallax>
                <div className={'home-body'}>
                    <Grid container spacing={2}>
                        <Grid div md={7} xs={12}>
                            <div className="vertical-center-container">
                                <div className="vertical-center">
                                    <Stack spacing={2} style={{textAlign: 'left', padding: '5%'}}>
                                        <Typography variant="h1" component="h1" style={{color: 'white'}}>
                                            ArtEvo
                                        </Typography>
                                        <Typography variant="h4" component="h4" style={{color: 'white'}}>
                                            ArtEvo is a boutique NFT gallery working with handpicked artists to deliver
                                            unique digital experiences
                                        </Typography>
                                        <Grid container spacing={2} className='button-container'
                                              style={{width: '50%', margin: '0 auto'}}
                                              rowSpacing={1}
                                        >
                                            <Grid item lg={6} xs={12} className='no-padding'
                                                  style={{marginTop: '10px', paddingRight: '5px'}}>
                                                <Button
                                                    style={{boxShadow: 'none'}}
                                                    size={'large'}
                                                    variant="contained"
                                                    onClick={() => navigate('/marketplace')}>Explore</Button>
                                            </Grid>
                                            <Grid item lg={6} xs={12} className='no-padding'
                                                  style={{marginTop: '10px', paddingLeft: '5px'}}>
                                                <Button
                                                    size={'large'}
                                                    variant="contained"
                                                    className='dark-button'
                                                    onClick={() => navigate('/contact')}
                                                >Contact</Button>
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                </div>
                            </div>
                        </Grid>
                        <Grid className='artist-card' div md={5} xs={12}>
                            <Carousel onSelect={(s) => {
                                setCurrentArtist(s)
                            }}>
                                {artists.map((item, i) =>
                                    <Carousel.Item key={`card-${i}`}>
                                        <img
                                            className="artist-card-image"
                                            src={item.img}
                                            alt={`${item.title} art`}
                                        />
                                    </Carousel.Item>
                                )}
                            </Carousel>
                            <div className='artist-card-info'>
                                <Typography variant="p" component="p" style={{color: 'white'}}>
                                    {artists[currentArtist].title}
                                </Typography>
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(artists[currentArtist].link)}>View
                                    Works</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>}
        </div>
        <Box
            sx={{
                bgcolor: 'background.paper',
                pt: 8,
                pb: 6,
            }}
        >
            <Container maxWidth="md">
                <Typography
                    component="h2"
                    variant="h3"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Our Mission
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                    Our mission is to showcase existing artists and to develop emerging artists in the form of NFT’s.
                    We see NFT’s as the next frontier for the art world and want to bring our years of experience in the
                    traditional art market into the world of NFT’s.
                </Typography>
            </Container>
        </Box>
        <Parallax bgImage={mural} strength={-100}>
            <div style={{height: '100vh'}}>
                <div style={insideStyles}>
                    <Button variant="text" onClick={() => navigate('marketplace')} style={{fontFamily: 'Comfortaa'}}>VIEW
                        OUR MARKET</Button>
                </div>
            </div>
        </Parallax>
    </Page>
}

export default HomePage