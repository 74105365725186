import React, {useEffect, useMemo, useState} from 'react'
import {client} from '../../lib/sanityClient'
import {useParams} from 'react-router-dom';

import {ThirdwebSDK as ThirdwebSDKNightly} from "@thirdweb-dev/sdk";
import Page from '../../components/Page'
import {alchemy} from "../../lib/alchemy";
import {marketplace} from "../../lib/marketplace";

import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import NFTCard from "../../components/NFTCard";
import {mode} from "../../lib/constants";

const Collection = () => {
    const collectionId = useParams().id || ''
    const [collection, setCollection] = useState({})
    const [nfts, setNfts] = useState([])
    const [listings, setListings] = useState([])
    const [loading, setLoading] = useState(true)

    const thirdwebSDK = useMemo(() => {
        return new ThirdwebSDKNightly(alchemy[mode].httpKey);
    }, [])

    const nftContract = useMemo(() => {
        return thirdwebSDK.getNFTCollection(collectionId)
    }, [thirdwebSDK])

    useEffect(() => {
        if (!nftContract) return
            ;
        (async () => {
            const nfts = await nftContract.getAll();
            const nftsTemp = [];
            nfts.map(r => {
                const newNFT = r.metadata;
                newNFT.owner = r.owner;
                nftsTemp.push(newNFT)
            })
            setNfts(nftsTemp)
        })()
    }, [nftContract])

    const marketPlaceModule = useMemo(() => {
        return thirdwebSDK.getMarketplace(
            marketplace[mode].contract
        )
    }, [thirdwebSDK])

    // get all listings in the collection
    useMemo(() => {
        if (!marketPlaceModule) return
            ;
        (async () => {
            setListings(await marketPlaceModule.getAllListings())
        })()
    }, [marketPlaceModule])

    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "marketItems" && contractAddress == "${collectionId}" ] {
      "imageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      volumeTraded,
      createdBy,
      contractAddress,
      "creator": createdBy->userName,
      title, floorPrice,
      "allOwners": owners[]->,
      description
    }`

        const collectionData = await sanityClient.fetch(query)
        setLoading(false)

        // the query returns 1 object inside of an array
        await setCollection(collectionData[0])
    }

    useEffect(() => {
        fetchCollectionData()
    }, [collectionId]);

    return (
        <Page>
            {loading ? <div style={{width: '100%', textAlign: 'center'}}><CircularProgress/></div> :
                <>
                    {collection ? <>
                            <div style={{
                                height: '30vh',
                                width: '100vw',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                display: 'flex',
                            }}>
                                <img
                                    style={{width: '100vw', objectFit: 'cover'}}
                                    src={
                                        collection?.bannerImageUrl
                                            ? collection.bannerImageUrl
                                            : 'https://via.placeholder.com/200'
                                    }
                                    alt="banner"
                                />
                            </div>
                            <div style={{marginTop: '-5%'}}>
                                <Avatar
                                    alt="Collection profile"
                                    style={{margin: '0 auto'}}
                                    src={collection?.imageUrl
                                        ? collection.imageUrl
                                        : 'https://via.placeholder.com/200'}
                                    sx={{width: 150, height: 150}}
                                />
                            </div>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                {collection.title}
                            </Typography>
                            <Typography
                                component="h4"
                                variant="h4"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                {collection.description}
                            </Typography>
                            <Container sx={{py: 8}} maxWidth="xl">
                                <Grid container spacing={4}>
                                    {nfts.map((nft, index) => (
                                        <NFTCard nft={nft} key={index} collection={collection} listings={listings}/>
                                    ))}
                                </Grid>
                            </Container>
                        </> :
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Collection not found
                        </Typography>
                    }
                </>
            }
        </Page>
    )
}

export default Collection