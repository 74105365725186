import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../../../components/Page'
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function FAQ() {
    let navigate = useNavigate();

    const handleLocationClick = (location) => {
        navigate(location)
    }

    return (
        <Page>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Link onClick={()=>handleLocationClick('/artists/giovanni-sechi')} style={{cursor: 'pointer'}}>Back</Link>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Nota Biografica
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h4"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Giovanni Sechi (Enoch Entronauta)
                        </Typography>
                        <Typography
                            component="p"
                            variant="p"
                            align="left"
                            color="text.primary"
                            gutterBottom
                        >
                            <p>
                                Giovanni Sechi (Enoch Entronauta) sin da piccolo manifesta talento nel disegno ma si
                                riavvicina all’ arte solo sul finire del 2010 in una fase della vita in cui intraprende
                                un viaggio introspettivo dal quale intuisce che molti interrogativi che si era posto
                                trovavano risposte nell’ arte stessa. Il cammino che compie alla ricerca di verità è
                                improntato su letture di scritture e libri ignoti ad un pubblico comune che lo
                                “Illuminano” e dai quali comprende il ruolo fondamentale che assumono i simboli nella
                                storia e cultura dell’ uomo influenzandone profondamente le coscienze. Inizia cosi a
                                condividere concetti come quelli espressi da Carl Gustav Jung: "Poiché ci sono
                                innumerevoli cose che oltrepassano l'orizzonte della comprensione umana, noi ricorriamo
                                costantemente all'uso di termini simbolici per rappresentare concetti che ci è
                                impossibile definire o comprendere completamente. Questa è una delle ragioni per cui
                                tutte le religioni e le tradizioni esoteriche impiegano un linguaggio simbolico o delle
                                immagini". (Jung "L'uomo e i suoi simboli"). Questo “studio autonomo” influenzerà in
                                modo indiscutibile il lavoro futuro dell’ artista trasformando molte sue opere in enigmi
                                intrisi di simbologia probabilmente destinati ad una comprensione per i posteri.
                                Il percorso di ricerca interiore lo condurrà a definirsi artisticamente un “Entronauta”,
                                mentre il nome artistico “Enoch” deriva dal personaggio biblico antidiluviano che
                                considera una delle figure chiave della storia umana e divina, individuo di cui non si
                                narra della sua morte, in questa scelta vi è anche l’ auspicio che la sua Arte possa
                                divenire eterna proprio come “Enoch”.
                                Artista autodidatta dipinge con una tecnica singolare, i suoi lavori ama definirli
                                “carezze” in virtù del suo modus operandi: dipingere a mani nude accarezzando il
                                “touchpad” del PC portatile nel programma Paint di Windows.
                            </p>
                            <p className='tab'>
                                Nel 2011 uno dei suoi primissimi lavori artistici viene selezionato per partecipare al
                                “Nazan Cigdem Art Contest” organizzato ad Istanbul nel quale verrà premiato dalla giuria
                                come “Promising Artist in digital category”. Nel 2012 viene invitato ad esporre a
                                Venezia presso lo Spazio Fondazione Xante Battaglia nella mostra “3 sensibilita autre”,
                                la partecipazione è accompagnata dalla presentazione del Maestro Battaglia: “Giovanni
                                Sechi (Enoch Entronauta), si impone nel panorama italiano, come artista visivo ;
                                realizzando opere in cui la visione pittorica si incontra con la fotografia e la
                                semantica (imposta con il suo nome). I suoi lavori hanno una luce particolare che esalta
                                i soggetti-mito : neo pop-art “fumetto” e citazioni attuali si fondono in un'unica,
                                affascinante linguistica!!”.
                            </p>
                            <p className='tab'>
                                Negl’anni a seguire i suoi lavori verranno selezionati per partecipare ed essere esposti
                                in premi e mostre internazionali all’ interno di location istituzionali e di prestigio
                                tra i quali a Roma nel Teatro dei Dioscuri del Quirinale e nella Biblioteca Angelica, a
                                Stupinigi nella Residenza Reale dei Savoia (ospiti i critici Philippe Daverio, Mattea
                                Micello e Silvia Ferrara), a Benevento nella Rocca dei Rettori (ospite il critico
                                Giorgio Grasso) , ad Amalfi negli antichi Arsenali ( ospiti il critico d’arte Luca
                                Cantore D’Amore) , a Padova nella Queen Art Studio Gallery (ospite Red Canzian dei
                                POOH), a Bologna nella Galleria Farini Concept di Palazzo Fantuzzi (ospite il critico
                                Vittorio Sgarbi) dove ha esposto con i grandi maestri del’ 900: Andy Warhol, Tano Festa,
                                Franco Angeli e Mario Schifano.
                            </p>
                            <p className='tab'>
                                “Molto spesso, ciò che crea una discriminante risiede nella tecnica, nel linguaggio
                                scelto da un artista, Giovanni Sechi, cosi come, ad esempio, avevano fatto Warhol e
                                Schifano o Festa, qui in mostra , sceglie di stravolgere la tradizione e di fare arte
                                mediante lo strumento “touch” del computer, attraverso le dita che non stendono il
                                pigmento ma delineano flussi di pixels creanti linee, disegni, nuances, filiazione di un
                                approccio ultra contemporaneo e metafisico , per certi versi. La fluidità dei sensi si
                                fa avvolgente pur non inficiando il messaggio ultimo dell’ opera e della poetica”, cosi
                                come ribadisce Azzurra Immediato (nella critica che accompagna l’ esposizione con i
                                grandi maestri del ‘ 900 a Bologna) anche il quotidiano La Nuova Sardegna (in occasione
                                della sua personale ad Alghero evento in cui l’ artista ha presentato un esposizione
                                caleidoscopica che ha condotto lo spettatore in un viaggio alchemico dove i dipinti
                                digitali trasmutavano in tele, opere indossate e proiezioni) ripropone il concetto di
                                come: “Giovanni Sechi, artista autodidatta, ha scelto di fare sua una tecnica in totale
                                controtendenza rispetto ai suoi numerosi colleghi che si occupano di arte digitale:
                                tutti i suoi dipinti – che l’autore definisce “carezze” – sono fatti interamente su
                                Paint “accarezzando” il touchpad del pc.”
                            </p>
                            <p className='tab'>
                                L’ opera “Deformazione professionale” accompagnata da una breve critica e nota
                                biografica dell’ autore viene pubblicata sullo storico annuario di Arte Contemporanea
                                “L’ Elite 2019 – Selezione arte”, nato nel 1974 e giunto alla 45 edizione, ogni anno
                                presenta le opere di circa 400 tra i migliori artisti italiani rappresentanti l'arte
                                contemporanea. Distribuito presso collezionisti privati ed appassionati d'arte di tutta
                                Italia, inoltre il volume è distribuito da Librerie Mondadori, Feltrinelli, Librerie
                                Universitarie e presso tutte le librerie italiane.
                                Viene selezionato dall’ associazione ArtetrA in collaborazione con Prince Group e
                                IconArt Magazine per partecipare al Gran Tour con esposizioni nel 2019 in alcune delle
                                più belle e affascinanti capitali d’ Europa e del Mondo: Praga, Madrid, Lisbona, Parigi
                                e Atene in location istituzionali e gallerie di prestigio. A Praga all’ Istituto
                                Italiano di cultura di Praga, a Madrid alla Galleria Gaudi, a Lisbona all’ Atelier
                                Natalia Gromicho, a Parigi alla Galleria Escape Thorigny – Le Marais, ad Atene all’
                                Istituto Italiano di cultura di Atene, le partecipazioni sono impreziosite dai premi e
                                dagli attestati di merito e stima ricevuti.
                            </p>
                            <p className='tab'>
                                L’ Opera “Société Angéliqué” contenente riferimenti all’ artista David Bowie viene
                                selezionata per la mostra tributo al Duca Bianco: “Far above the moon”, a 50 anni dall’
                                uscita di Space Oddity, la collettiva si è tenuta a Roma alla Galleria Spazio Cima nel
                                particolare quartiere della capitale Coppedè. La mostra presentata
                                dall’attrice/scrittrice Alessandra Izzo aveva come tema Bowie e lo spazio con un grande
                                successo di pubblico e risalto mediatico.
                            </p>
                            <p className='tab'>
                                Viene invitato da Maria Grazia Todaro ArtDirector della QueenArtStudio Gallery di Padova
                                ad esporre a Murano (Venezia) nel periodo della 58. Biennale di Venezia in The Glass
                                Cathedral ex Chiesa di Santa Chiara nell’ evento “Murano Art Expo 2019”, nell’ occasione
                                presenta per la prima volta al pubblico il cortometraggio “Le carezze degli Angeli” per
                                il quale riceve l’attestato di competenza artistica alla presenza del Dott. Luigi
                                Mattera Cavaliere della Stella d’Italia (insignito dal Presidente Sergio Mattarella) ed
                                editore della rivista Royal Monaco del Principato. Il cortometraggio verrà proiettato
                                nei mesi successivi nella vetrina della centralissima Galleria di Padova per poi essere
                                in expo a Bangkok per il premio “It is humanity” al Museo BACC per la quale
                                partecipazione viene insignito del titolo “International Artist Master”.
                            </p>
                            <p className='tab'>
                                Il dipinto “Magnus Opus, Nigredo” è selezionato dalla Start group (curatori dell’
                                Atlante di arte contemporanea edito De Agostini, della Biennale di Venezia e della
                                Triennale di arti visive di Roma) per il prestigioso evento a Venezia: Padiglione
                                Europa, mostra d’arte dedicata ai migliori talenti del panorama contemporaneo europeo,
                                in un contesto di primissimo livello come il Palazzo Albrizzi Capello, ospitante i tre
                                padiglioni della 58. Biennale di Venezia: Guatemala, Grenada e Repubblica Dominicana,
                                presso la sede della Biblioteca del Teatro Contemporaneo Europeo. Ospite del vernissage
                                il curatore della Biennale Simone Pieralice.
                            </p>
                            <p className='tab'>
                                L’ opera “La gravità non esiste” (premiata nel 2017 a Roma alla Biblioteca Angelica in
                                occasione del Premio Openart2017 organizzato da Marguttarte) viene selezionata per la
                                mostra/evento in onore della celebre astrofisica Margherita Hack tenutasi alla Milano
                                Art Gallery, ospiti Carlo Motta di Editoriale Giorgio Mondadori. il fotografo di fama
                                internazionale Roberto Villa, la presidente di Women Empowerment Flavia Sagnelli, il
                                noto sociologo Francesco Alberoni, la psicologa Cristina Cattaneo, il biologo Tancredi
                                Militano e Patrick di Striscia La Notizia. Tra i contributi dell’esposizione si citano
                                Morgan, il grande fisico Zichichi, Umberto Veronesi, Silvana Giacobini, don Antonio
                                Mazzi e Amanda Lear.
                                Pubblicazione sul bimestrale di Arte, Cultura e Spettacolo IconArt Magazine di
                                Maggio/Giugno 2019 con una breve nota biografica e le opere “Magnus Opus,Nigredo” e “La
                                stanza del tempo, fase III” a cui IconArt Magazine dedica anche una recensione online.
                            </p>
                            <p className='tab'>
                                Nel 2019 la sua arte si affaccia con successo anche nel mondo del teatro e della musica.
                                I suoi dipinti vengono utilizzati come scenografia nello spettacolo teatrale itinerante
                                “Casa di Bambola” curato e interpretato dall’ attrice internazionale Klodi Kadillari e
                                presentato in alcune location in Sardegna ; idealizza il dipinto “Genna Silana” che
                                verrà utilizzato sia come contributo visivo per il singolo omonimo del musicista sardo
                                Marino De Rosas e immagine background dell’ Album Intrinada .
                            </p>
                            <p className='tab'>
                                Nel 2020 la sua esposizione presso la QueenArtStudio Gallery è accompagnata dall’
                                attestato di merito come artista contemporaneo 2020 con il riconoscimento di Jose Van
                                Roy Dali, figlio del piu noto Salvador.
                                Premiato con la “Palma d’ oro per le arti visive 2020” dall’ autorevole Commissione
                                Critica Internazionale di ArtExpo Gallery. La premiazione programmata per il 19/09/2020
                                in Costa Azzurra a Cannes nella sala Cytrus del luxury Hotel Martinez in Boulevard de la
                                Croisette a causa dello stato di emergenza sanitario della Francia si è svolta in Italia
                                nella medesima data presso un’ altra splendida location, sala conferenze Hotel Principe
                                sito sul Canal Grande a Venezia. La motivazione critica è stata curata dalla Dott.ssa
                                Belgiovine: “Un’ artista che traduce la sua realtà con l’ ampiezza dei simboli in un
                                contesto di surreale astrazione modulata dalle istintive indagini mentali. La percezione
                                della realtà si trasforma con la suggestiva personalità creativa, superando le indagini
                                allegoriche di una raffinata coreografia che rivela la sua sensibilità artistica”.
                                Nell’ Ottobre del 2020, l’ opera “Prada in red zone with De Chirico” esposta in digital
                                exibition in due dei sette emirati arabi in occasione dell’ evento internazionale
                                Emirates Art Connection 2020 in due location prestigiose: Art Hub Al Khateem – Abu Dhabi
                                e Al Fahidi Historical Neighbouhood, Villa 17 – Dubai alla presenza di ospiti VIP e
                                membri della famiglia reale (sceicco)
                                La Start Group seleziona l’ opera “Stringhe eterotiche, movimento 5“ per il prestigioso
                                evento della Triennale di Arti Visive di Roma svoltosi nel Giugno del 2021, l opera
                                facente parte della serie “Stringhe eterotiche” è stata realizzata appositamente per
                                l’evento, il maestro poi per scelta personale. ha deciso di non aderire alla
                                partecipazione.
                            </p>
                            <p className='tab'>
                                Nel 2021 due opere in copertina del Maestro accompagnano visivamente i libri della
                                scrittrice albanese Klodiana Kadillari, “Il Rimorso” con testo in lingua italiana e
                                “Feruziana” con testo in lingua albanese.
                                Nell’ Agosto del 2021 Straordinaria “Exibithion Art” del maestro Giovanni Sechi (Enoch
                                Entronauta) intitolata Arte Apocrifa , “Le carezze degli Angeli” antologica 2011-2021.
                                L’ evento è stato presentato a Tirana dalla celebre artista albanese Klodiana Kadillari
                                in una location d’eccezione: la “Reja” (The Cloud) opera dell’ architetto giapponese Sou
                                Fujimoto , uno dei più noti progettisti di architettura contemporanea al mondo . La
                                maestosa installazione di Fujimoto è custodita nei giardini della Galleria Nazionale d’
                                Arte a Tirana ed è stata la cornice perfetta per le oltre 200 opere proposte dal maestro
                                sardo intervallate da frammenti di “work in progress” a testimonianza della
                                straordinaria e innovativa tecnica di Sechi (dipingere con le dita “accarezzando” il
                                touchpad del pc portatile in Paint di Windows). L’ esposizione ha condotto il pubblico
                                in un viaggio multimediale ripercorrendo e sintetizzando oltre 10 anni (2011/2021) di
                                operato artistico con opere accuratamente selezionate dal maestro, si evidenzia in
                                questo lavoro l’ evoluzione stilistica della tecnica impreziosita dalla costante ricerca
                                di contenuti. “La grande bellezza femminile” è stato Il filo conduttore dei dipinti
                                proposti con lavori ispirati a modelle che ne hanno segnato inevitabilmente il percorso
                                artistico, tra le opere da segnalare un omaggio all’ artista Klodiana Kadillari che ha
                                curato e reso possibile l’ evento in collaborazione con il Comune di Tirana e un omaggio
                                a Gloria Guida icona di bellezza italiana che ha elogiato di recente sul suo profilo
                                Instagram il lavoro del maestro. Graditi riscontri da Ornella Muti e Beatrice Vendramin
                                a seguito delle opere esposte a loro dedicate.
                                Il 16 Ottobre 2021 nel Principato di Monaco il Maestro sardo è stato insignito dall’
                                autorevole Commissione Critica Internazionale di ArtExpò Gallery del prestigioso premio
                                “Oscar della Creatività” per le Arti visive, il riconoscimento è stato assegnato per le
                                comprovate capacità creative e l’ affermata professionalità.
                                Organizzato da ArtExpò Gallery, con al suo attivo 25 anni di eventi, il premio è un
                                importante appuntamento biennale dell’Arte giunto alla undicesima edizione.
                                La premiazione si è svolta in una location d’ eccezione, il salone Marigold del rinomato
                                Montecarlo Bay Hotel e Resort, Hotel ufficiale del Rolex Monte-Carlo masters di tennis.
                                La consegna dell’ Oscar è stata preceduta dalla presentazione delle due opere firmate
                                Sechi “La Pietà” e “La Vergine e lo Spirito Santo” selezionate dalla Commissione
                                Internazionale composta da critici, periti, giornalisti e collezionisti d’ Arte.
                                Motivazione critica della dott.ssa Rosanna Chetta: “le vibranti emozioni che si colgono
                                alla vista di queste opere, irrompono nell’ animo con forza e decisione, materializzando
                                sempre nuove consapevolezze. Gli inediti percorsi visivi, procedono in favore di un
                                assoluta libertà d’ espressione, potenziando il complesso tracciato dai soggetti
                                compositivi.”
                            </p>
                            <p className='tab'>
                                Oltre a premi e riconoscimenti l’ artista vanta innumerevoli menzioni su articoli di
                                stampa (regionali, nazionali ed esteri) e su riviste di Arte e Cultura per gli eventi di
                                cui è stato partecipe. Ha sempre riscontrato un ottimo giudizio di critica e pubblico
                                durante l’ esposizioni, in più occasioni incredulità da parte dello spettatore nell’
                                apprendere che la tela esposta fosse un dipinto digitale all’origine, l’ occhio di chi
                                osserva è spesso ingannato dall’ opera che quando diviene quadro appare come un lavoro
                                di pittura tradizionale, lo stupore, la perplessità e lo scetticismo invadono sia il
                                pubblico ma anche gli addetti ai lavori ancor di più quando l’ artista spiega il suo
                                “modus operandi” cosi arduo da attuare. (Per tale motivo l’ artista si è sentito in
                                dovere di realizzare alcuni video che catturano alcuni momenti di “work in progress”
                                come prova tangibile della sua tecnica.)
                                La ricerca e la volontà di dare dinamicità e movimento all’ arte lo hanno indotto a
                                creare a partire dal 2013 delle serie di opere stampate su t-shirt ad edizione limitata
                                con pezzi unici ed esclusivi, da collezione. Il concetto di moto e vitalità dell’ arte
                                si è concretizzato al meglio quando ha deciso di donare alcune opere sviluppate su
                                t-shirt ad alcune modelle e personaggi di fama mondiale stimati tra cui Rihanna e Magic
                                Johnson incontrati in Sardegna nella splendida cornice di Porto Cervo (negli anni in cui
                                ha collaborato con una delle aziende leader nella moda del lusso, Prada), questo ha reso
                                possibile il raggiungimento di Stati come America, Regno Unito, Spagna, Francia,
                                Principato di Monaco, Tunisia, Russia, Albania, Jamaica, Repubblica Ceca.... con
                                testimonial di eccezione. Le sue edizioni limitate di opere su t-shirt sono state
                                accompagnate spesso anche da Exhibition di presentazione: (2013) Porto Cervo nel MDM
                                Museum ospitante la mostra di Andy Warhol curata da Achille Bonito Oliva, (2016) Porto
                                Cervo nella boutique Sea & Snow, (2017) Terni nell’ expo curato dall’ artista Alessandra
                                Botondi, (2018) Alghero in occasione della sua personale con presentazione danzante
                                “Arte in Movimento”, (2019) Parigi nell’ evento Art Paris - Premio Picasso, (2020) alla
                                QueenArtStudio Gallery.
                            </p>
                            <p className='tab'>
                                Nel suo spirito di fratellanza e amore per il prossimo ha donato quadri a Chiese, Scuole
                                e Associazioni.
                            </p>
                            <p className='tab'>
                                Nel corso di oltre 10 anni di intenso lavoro artistico, ricerca costante di contenuti e
                                bellezza, ha creato un portfolio di oltre 3000 dipinti digitali, sviluppando una tecnica
                                sempre più “amabile e limpida” come viene definita nell’ annuario l’ Elitè 2019,
                                spingendo il programma base di pittura di Windows dove forse pochi al mondo sono stati
                                in grado di arrivare con un tale livello di tecnica e stile, e dove gli stessi creatori
                                del programma probabilmente non avrebbero osato immaginare. In questo lungo percorso
                                artistico di impegno, sacrificio e dedizione ama ricordare che quando l’ Arte lo chiama,
                                paragonabile ad un richiamo divino, con se ha solo delle penne e qualche vecchio diario,
                                inizia cosi a fare alcuni disegni in quelle pagine vuote e poi ha l’ intuizione di
                                scannerizzare quegli schizzi e colorarli col programma Paint del PC, in pochissimo tempo
                                raggiunge una buona manualità col “touchpad” che gli permette di realizzare i suoi
                                dipinti digitali senza alcun disegno preliminare, il resto è storia dell’Arte!. Oggi ha
                                la consapevolezza che in quel momento particolare della sua esistenza qualsiasi
                                strumento sarebbe stato buono ed utile per dar luce all’ immenso potenziale creativo,
                                accettando quel talento che per troppi anni era stato perduto, represso e taciuto.
                                Gli artisti che lo hanno maggiormente influenzato sono Caravaggio, Salvador Dalí, René
                                Magritte e Storm Thorgerson
                            </p>
                        </Typography>
                    </Container>
                </Box>
            </main>
        </Page>
    );
}
//export default About;