import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../../components/Page'
import monitors from '../../../assets/artists/giovannisechi/Monitors.jpg'
import maestrale from '../../../assets/artists/giovannisechi/Maestrale nell_ isola  - Anno 2020.jpg'
import logo from '../../../assets/artists/giovannisechi/Logo Giovanni Sechi copy.jpg'
import pantera from '../../../assets/artists/giovannisechi/la pantera rosa.jpg'
import quilibrio from '../../../assets/artists/giovannisechi/Equilibrio pirandelliano.jpg'
import ambiguto from '../../../assets/artists/giovannisechi/AmbiguitÖ lunare.jpg'
import {useNavigate} from "react-router-dom";
import {Carousel} from "react-bootstrap";
import {Button} from "@mui/material";
import '../../HomePage/HomeStyle.css'
import {useState} from "react";
import AssetViewModal from "../../../components/AssetViewModal";
import {isMobile} from "react-device-detect";


const cards = [
    {
        "name": "Monitors",
        "bio": "Description",
        "image": monitors,
        "link":"/collection/0x9F9CB9A09C59BBcF5E2dd0948761ac0e65D9247E/0",
        "showLink": true
    },
    {
        "name": "Maestrale nell_ isola",
        "bio": "Description",
        "image": maestrale,
        "link":"/collection/0x9F9CB9A09C59BBcF5E2dd0948761ac0e65D9247E/1",
        "showLink": true
    },
    {
        "name": "la pantera rosa",
        "bio": "Description",
        "image": pantera,
        "link":"/collection/0x9F9CB9A09C59BBcF5E2dd0948761ac0e65D9247E/2",
        "showLink": true
    },
    {
        "name": "Equilibrio pirandelliano",
        "bio": "Description",
        "image": quilibrio,
        "link":"/collection/0x9F9CB9A09C59BBcF5E2dd0948761ac0e65D9247E/3",
        "showLink": true
    },
    {
        "name": "AmbiguitÖ lunare",
        "bio": "Description",
        "image": ambiguto,
        "link":"/collection/0x9F9CB9A09C59BBcF5E2dd0948761ac0e65D9247E/4",
        "showLink": true
    }
];

export default function About() {
    let navigate = useNavigate();
    const [currentPiece, setCurrentPiece] = useState(0);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetToShow, setAssetToShow] = useState('');

    return (
        <Page>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Giovanni Sechi
                        </Typography>
                        <div style={{width: '100%', textAlign: 'center', paddingBottom: '24px'}}>
                            <img src={logo} style={{margin: '0 auto', maxWidth: '300px'}}/>
                        </div>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Giovanni Sechi (aka Enoch Entronauta) is a self-taught artist who paints with an
                            extraordinary technique,
                            he describes his paintings as "caresses" by virtue of his Modus Operandi: "paint with your
                            bare hands
                            stroking the touchpad of your laptop in Microsoft’s Windows Paint".
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            At an early age Giovanni showed interest and talent in drawing but only recently, in 2010, did he
                            really begin exploring the art world more deeply. He embarked on an introspective journey in which
                            he discovered that many of his life questions could be answered through art. Through these answers
                            he began to define himself artistically as an "Entronaut". The artistic name "Enoch" is in honor
                            of the antediluvian biblical character.
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Over the years, his works have been selected for authoritative publications and exhibitions
                            in international
                            awards and exhibitions within institutional venues and prestigious galleries in Abu Dhabi,
                            Dubai, Paris,
                            Madrid, Lisbon, Athens, Prague, Bangkok, Montecarlo, Tirane, Venice, Rome, Milan, Porto
                            Cervo, Amalfi, and more.
                            In addition to the prizes and awards received in Italy and abroad, his art boasts excellent
                            reviews
                            from critics and the public.
                        </Typography>
                        {/*                        <Stack
                            sx={{pt: 4}}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant="contained" onClick={()=>handleLocationClick('/artists/giovanni-sechi/bio')}>Full Bio</Button>
                        </Stack>*/}
                    </Container>
                </Box>
                <Container sx={{py: 8}} maxWidth="lg">
                    <Typography
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Works
                    </Typography>
                    <Grid className='artist-card' md={5} xs={12} style={{
                        margin: '0 auto',
                        width: '90%',
                        maxWidth: isMobile ? '90%' : '60%',
                        height: isMobile ? '55vh' : ''
                    }}>
                        <Carousel onSelect={(s) => {
                            setCurrentPiece(s)
                        }}>
                            {cards.map(item =>
                                <Carousel.Item onClick={() => {
                                    setShowAssetModal(true)
                                    setAssetToShow(cards[currentPiece])
                                }}>
                                    <div style={{backgroundColor: 'lightgray'}}>
                                        <img
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            className="artist-card-image"
                                            src={item.image}
                                            alt={`${item.title} art`}
                                        />
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className='artist-card-info' style={{height: isMobile ? '10vh' : ''}}>
                            <Typography variant="p" component="p" style={{color: 'white'}}>
                                {cards[currentPiece].name}
                            </Typography>
                            {cards[currentPiece].showLink &&
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(cards[currentPiece].link)}>View In
                                    Market</Button>
                            }
                        </div>
                    </Grid>
                </Container>
            </main>
            <AssetViewModal open={showAssetModal} handleClose={() => setShowAssetModal(false)} asset={assetToShow.image}
                            assetName={assetToShow.name}/>
        </Page>
    );
}
//export default About;