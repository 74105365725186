import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../components/Page'
import Gordon from '../../assets/theTeam/gordon.jpg'
import Morgan from '../../assets/theTeam/morgan.jpg'
import Ksensia from '../../assets/theTeam/ksenia.jpg'

const cards = [
    {
        "name": "Morgan Haynes",
        "title": "Co-Founder & CEO",
        "bio":"Morgan is an early adopter and investor in cryptocurrency and blockchain technology. He has been living in Tbilisi, Georgia for " +
            "nearly 3 years now and has gained valuable insight into how cryptocurrency and blockchain technology function and the significant " +
            "benefits they will bring to the art world alongside many other industries.",
        "image":Morgan
    },
    {
        "name": "Ksenia Pantina",
        "title": "Co-Founder & Art Director",
        "bio":"Ksenia brings a background in Fine Arts, is an Art consultant for private clients worldwide and an Art agent to emerging artists on the international Art scene",
        "image":Ksensia
    },
    {
        "name": "Gordon MacMaster",
        "title": "CTO",
        "bio":"Gordon is a fullstack software engineer bringing experience from Tesla and the asset management industry. In his free time (what's that?) Gordon enjoys skiing, hiking, camping and spending time with friends",
        "image":Gordon
    }
];

export default function About() {
    return (
        <Page>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Our Mission
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Our mission is to showcase existing artists and to develop emerging artists in the form of NFT’s.
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            We see NFT’s as the next frontier for the art world and want to bring our years of experience in the traditional art market into the world of NFT’s.
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            We are a boutique NFT gallery that has handpicked artists that we believe
                            offer significant value in order to help collectors make the right choice in the expansive new NFT market.
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            We have brought together talents in the industry by a team of experts from the US, Europe, and the UK. Our challenge
                            is to present an international mix of artists, both established and emerging talents, who we believe will have a strong impact on the digital art market.
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            The NFT market is thriving and we are here to guide you through this process.
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="lg">
                    <Typography
                        component="h2"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        The Team
                    </Typography>
                    <Grid container spacing={4}>
                        {cards.map((card, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        image={card.image}
                                        alt="random"
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {card.name}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="h4">
                                            {card.title}
                                        </Typography>
                                        <Typography>
                                            {card.bio}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
        </Page>
    );
}
//export default About;