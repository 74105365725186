import akiraKurosava from './akira-kurosava.jpg';
import albertCamus from './albert-camus.jpg'
import andyWarhol from './andy-warhol.jpg'
import federicoFellini from './federico-fellini.jpg'
import franzKafka from './franz-kafka.jpg'
import gGurjieff from './g-gurjieff.jpg'
import henryMiller from './henry-miller.jpg'
import hermanHesse from './hermann-hesse.jpg'
import jdSalinger from './j-d-salinger.jpg'
import jamesJoyce from './james-joyce.jpg'
import josefBeuys from './josef-beuys.jpg'
import luisBunuel from './luis-bunuel.jpg'
import marcelDuchamp from './marcel-duchamp.jpg'
import marcelProust from './marcel-proust.jpg'
import milesDavis from './miles-davis.jpg'
import pierPaoloPasolini from './pier-paolo-pasolini.jpg'
import sigmundFreud from './sigmund-freud.jpg'
import thomasEliot from './thomas-eliot.jpg'

const wales = {
    akiraKurosava,
    albertCamus,
    andyWarhol,
    federicoFellini,
    franzKafka,
    gGurjieff,
    hermanHesse,
    henryMiller,
    jdSalinger,
    jamesJoyce,
    josefBeuys,
    luisBunuel,
    marcelProust,
    marcelDuchamp,
    milesDavis,
    pierPaoloPasolini,
    sigmundFreud,
    thomasEliot
}

export default wales;