const getListingItemV1 = (listings = [], nft, collection)=> {
    const collectionContract = collection.contractAddress
    for(const l in listings){
        const listing = listings[l];
        const collectionMatches = listing.assetContractAddress === collectionContract;
        const idMatches = listing.asset.id === nft.id;
        const quantity = parseInt(listing.quantity?._hex || '0x00', 16)
        if(quantity > 0 && idMatches && collectionMatches){
            return listing;
        }
    }
    return null;

}

const getListingItem = (listings = [], nft={}, collection)=> {
    const collectionContract = collection.contractAddress
    for(const l in listings){
        const listing = listings[l];
        const collectionMatches = listing.assetContractAddress === collectionContract;
        let idMatches = false;
        if(nft){
            idMatches = nft && parseInt(listing.asset.id, 16).toString() === parseInt(nft.id._hex, 16).toString();
        }
        const quantity = parseInt(listing.quantity?._hex || '0x00', 16)
        if(quantity > 0 && idMatches && collectionMatches){
            return listing;
        }
    }
    return null;

}
export {getListingItem}