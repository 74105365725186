import React, {useEffect, useState, useRef} from 'react'
import {useWeb3} from '@3rdweb/hooks'
import moment from 'moment'
import {client} from '../../lib/sanityClient'
import Page from '../../components/Page'

import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import InputLabel from '@mui/material/InputLabel';


const EditAccount = () => {
    const {address} = useWeb3()
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [editsMade, setEditsMade] = useState(false)
    const [reload, setReload] = useState(false)
    const [newUserName, setNewUserName] = useState('')
    const [newInstagram, setNewIg] = useState('')
    const [newTwitter, setNewTwitter] = useState('')
    const [newProfile, setNewProfile] = useState(null)
    const [newBanner, setNewBanner] = useState(null)
    const [imageUploadFor, setImageUploadFor] = useState('')
    const inputFile = useRef(null)
    const bannerImage = useRef(null)
    const profileImage = useRef(null)

    const addressToUse = address

    useEffect(() => {
        if (!addressToUse) return
            ;
        (async () => {
            setUser(null)
            const query = `*[_type == "users" && _id == "${addressToUse}"] {
      "profileImageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      twitterHandle,
      _id,
      _createdAt,
      igHandle,
      walletAddress,
      userName
    }`

            client.fetch(query).then(users => {
                if (users.length > 0) {
                    setUser(users[0])
                    setNewUserName(users[0].userName)
                    setNewIg(users[0].igHandle || '')
                    setNewTwitter(users[0].twitterHandle || '')
                }
                setLoading(false)
            }).catch(e => {
                setLoading(false)
                console.log(e)
            })
        })()
    }, [addressToUse])

    if (!addressToUse && !loading) {
        return <Page>
            <div style={{padding: '10vh'}}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Connect Wallet To View Account
                </Typography>
            </div>
        </Page>
    }

    const getImage = async (id, fileName) => {
        return new Promise(resolve => {
            const img = document.getElementById(id)
            fetch(img.src)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], fileName, {type: 'image'})
                    return resolve(file)
                })
        })
    }

    const uploadImage = async (docID, file, htmlID) => {
        const image = await getImage(htmlID, file.name)
        client.assets
            .upload('image', image, {
                filename: file.name
            })
            .then(imageAsset => {
                // Here you can decide what to do with the returned asset document.
                // If you want to set a specific asset field you can to the following:
                return client
                    .patch(user._id)
                    .set({
                        [htmlID]: {
                            _type: 'image',
                            asset: {
                                _type: "reference",
                                _ref: imageAsset._id
                            }
                        }
                    })
                    .commit()
            })
    }

    const save = async () => {
        const update = {
            igHandle: newInstagram,
            twitterHandle: newTwitter,
            userName: newUserName
        }
        if (newProfile) {
            uploadImage(user._id, newProfile, 'profileImage',)
        }
        if (newBanner) {
            uploadImage(user._id, newBanner, 'bannerImage',)
        }
        client
            .patch(user._id) // Document ID to patch
            .set(update) // Shallow merge
            .commit() // Perform the patch and return a promise
            .then((e) => {
                setEditsMade(false)
                alert('Profile updated. It will take a few minutes to reflect changes across the app. Do not worry if your changes dissapear after reload')
            })
            .catch((err) => {
                alert("An error occurred")
                console.error('Oh no, the update failed: ', err.message)
            })
    }

    const openBrowser = () => {
        inputFile.current.click();
    };

    const handleFileChange = (e) => {
        const newFile = e.target.files[0];
        if (!newFile) {
            alert("No file selected")
        } else {
            //
            if (newFile.size > 10000000) {
                alert('File too large')
            } else {
                const reader = new FileReader();
                let current;
                if (imageUploadFor === 'profile') {
                    current = profileImage.current;
                    setNewProfile(newFile)
                } else {
                    current = bannerImage.current;
                    setNewBanner(newFile)
                }
                current.file = newFile;
                reader.onload = (e) => {
                    switch (imageUploadFor) {
                        case 'profile':
                            user.profileImageUrl = e.target.result;
                            break;
                        default:
                            user.bannerImageUrl = e.target.result;
                            break;
                    }
                    current.src = e.target.result;
                    setEditsMade(true);
                    const newReload = !reload;
                    setReload(newReload)
                }
                reader.readAsDataURL(newFile);
            }
        }
    }

    return (
        <Page>
            {!address ? <Typography
                style={{padding: '5em'}}
                component="h2"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Connect wallet to view account
            </Typography> : <>
                {loading ? <div style={{width: '100%', textAlign: 'center'}}><CircularProgress/></div> :
                    <>
                        {user ? <>
                                <div style={{
                                    height: '30vh',
                                    width: '100vw',
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                    display: 'flex',
                                }}>
                                    <img
                                        style={{width: '100vw', objectFit: 'cover'}}
                                        src={
                                            user?.bannerImageUrl
                                                ? user.bannerImageUrl
                                                : 'https://via.placeholder.com/200'
                                        }
                                        alt="banner"
                                    />
                                </div>
                                <div style={{marginTop: '-5%'}}>
                                    <Avatar
                                        alt="Collection profile"
                                        style={{margin: '0 auto'}}
                                        src={user?.profileImageUrl
                                            ? user.profileImageUrl
                                            : 'https://via.placeholder.com/200'}
                                        sx={{width: 150, height: 150}}
                                    />
                                </div>
                                <Typography
                                    component="h1"
                                    variant="h2"
                                    align="center"
                                    color="text.primary"
                                >
                                    Profile Settings
                                </Typography>
                                <Typography
                                    component="h5"
                                    variant="h5"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                >
                                    Joined {moment(user._createdAt).format('MMMM YYYY')}
                                </Typography>
                                <Container sx={{py: 8}} maxWidth="md">
                                    <div style={{width: '100%', padding: '16px'}}>
                                        <TextField
                                            style={{width: '100%'}}
                                            id="outlined-basic"
                                            label="Username"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setNewUserName(e.target.value);
                                                setEditsMade(true)
                                            }}
                                            value={newUserName}
                                        />
                                    </div>
                                    <div style={{width: '100%', padding: '16px'}}>
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <TwitterIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{width: '100%'}}
                                            id="outlined-basic"
                                            label="Twitter"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setNewTwitter(e.target.value);
                                                setEditsMade(true)
                                            }}
                                            value={newTwitter}
                                        />
                                    </div>
                                    <div style={{width: '100%', padding: '16px'}}>
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <InstagramIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{width: '100%'}}
                                            id="outlined-basic"
                                            label="Twitter"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setNewIg(e.target.value);
                                                setEditsMade(true)
                                            }}
                                            value={newInstagram}
                                        />
                                    </div>
                                    <div style={{width: '100%', padding: '16px', textAlign: 'center'}}>
                                        <InputLabel htmlFor="input-with-icon-adornment">
                                            Banner Image
                                        </InputLabel>
                                        <img
                                            id={'bannerImage'}
                                            ref={bannerImage}
                                            onClick={() => {
                                                setImageUploadFor('banner');
                                                openBrowser();
                                            }}
                                            style={{height: 150, objectFit: 'cover', cursor: 'pointer', margin: '0 auto'}}
                                            src={
                                                user?.bannerImageUrl
                                                    ? user.bannerImageUrl
                                                    : 'https://via.placeholder.com/200'
                                            }
                                            alt="banner"
                                        />
                                    </div>
                                    <div style={{width: '100%', padding: '16px', textAlign: 'center'}}>
                                        <InputLabel htmlFor="input-with-icon-adornment">
                                            Profile image
                                        </InputLabel>
                                        <img
                                            id={'profileImage'}
                                            ref={profileImage}
                                            onClick={() => {
                                                setImageUploadFor('profile');
                                                openBrowser();
                                            }}
                                            style={{height: 150, objectFit: 'cover', cursor: 'pointer', margin: '0 auto'}}
                                            src={user?.profileImageUrl
                                                ? user.profileImageUrl
                                                : 'https://via.placeholder.com/200'}
                                            alt="banner"
                                        />
                                    </div>
                                    <div style={{width: '100%', padding: '16px'}}>
                                        <TextField
                                            disabled
                                            style={{width: '100%'}}
                                            id="outlined-basic"
                                            label="Wallet Address"
                                            variant="outlined"
                                            value={address}
                                        />
                                    </div>
                                    <div style={{textAlign: 'center', width: "100%"}}>
                                        <Button variant={"contained"} disabled={!editsMade} onClick={save}>Save</Button>
                                    </div>
                                </Container>
                            </> :
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                User not found
                            </Typography>
                        }
                    </>
                }</>}
            <input accept="image/*" type='file' id='file' ref={inputFile} style={{display: 'none'}}
                   onChange={handleFileChange}/>
        </Page>
    )
}

export default EditAccount