import React from 'react';
import Footer from '../Footer'
import Navbar from "../Navbar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const Page = (props) => {
    const theme = createTheme(
        {
            typography: {
                fontFamily: [
                    'Comfortaa'
                ].join(','),
            },
        }
    );
    return <ThemeProvider theme={theme}>
        <div className="page">
            <CssBaseline />
            <Navbar />
            {props.children}
        </div>
        <Footer/>
    </ThemeProvider>
}

export default Page