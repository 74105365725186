import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { SocialIcon } from 'react-social-icons';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'© '}
            {new Date().getFullYear()}
            {' by '}
            <Link color="inherit" href="/">
                ArtEvo
            </Link>
        </Typography>
    );
}

export default function StickyFooter() {
    return <div className="footer">
        <Box
        component="footer"
        sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[500]
                    : theme.palette.grey[800],
        }}
    >
        <Container maxWidth="sm" style={{textAlign: 'center', color: 'white'}}>
            <Copyright />
            <IconButton aria-label="join our discord" href={'https://discord.gg/EBgHmJxR7J'} target={'_blank'}>
                <SocialIcon url="https://discord.gg/EBgHmJxR7J" />
            </IconButton>
        </Container>
    </Box></div>

}