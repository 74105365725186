import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../../components/Page'
import col1_1 from '../../../assets/artists/mari/=AFISHA FR.tif - Copy.jpg'
import col1_2 from '../../../assets/artists/mari/=AFISHA FR.tif copy.jpg'
import col1_3 from '../../../assets/artists/mari/=AFISHA FR.tif  copy(2).jpg'
import col2_1 from '../../../assets/artists/mari/_V6C2413 - Copy.jpg'
import col2_2 from '../../../assets/artists/mari/_V6C2465 - Copy - Copy.jpg'
import col2_3 from '../../../assets/artists/mari/_V6C2465 copy.jpg'
import col2_4 from '../../../assets/artists/mari/_V6C2465 copy - Copy.jpg'
import col2_5 from '../../../assets/artists/mari/_V6C2519 - Copy.jpg'
import col2_6 from '../../../assets/artists/mari/_V6C2465 copy - Copy (2).jpg'
import col3_1 from '../../../assets/artists/mari/IMG_3908 - Copy - Copy.jpg'
import col3_2 from '../../../assets/artists/mari/IMG_3896 - Copy.jpg'
import col3_3 from '../../../assets/artists/mari/IMG_3896 - Copy - Copy.jpg'
import col3_4 from '../../../assets/artists/mari/IMG_3896 - Copy - Copy (2).jpg'
import col3_5 from '../../../assets/artists/mari/IMG_3896 - Copy - Copy (3).jpg'
import col3_6 from '../../../assets/artists/mari/IMG_3908 - Copy.jpg'
import col4_1 from '../../../assets/artists/mari/IMG_6067 copy.jpg'
import col4_2 from '../../../assets/artists/mari/IMG_6067 copy - Copy.jpg'
import col4_3 from '../../../assets/artists/mari/IMG_6069 copy.jpg'
import col4_4 from '../../../assets/artists/mari/IMG_6760-Copy.jpg'
import col4_5 from '../../../assets/artists/mari/IMG_6760 - Copy.jpg'
import col4_6 from '../../../assets/artists/mari/IMG_6069 copy - Copy.jpg'
import col4_7 from '../../../assets/artists/mari/IMG_6760 - Copy (2).jpg'
import col4_8 from '../../../assets/artists/mari/IMG_6760 - Copy - Copy.jpg'
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {isMobile} from "react-device-detect";
import {Carousel} from "react-bootstrap";
import {Button} from "@mui/material";
import AssetViewModal from "../../../components/AssetViewModal";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const cards = [
    {
        "name": "",
        "bio": "",
        "image": col1_1
    },
    {
        "name": "",
        "bio": "",
        "image": col1_2
    },
    {
        "name": "",
        "bio": "",
        "image": col1_3
    },
    {
        "name": "",
        "bio": "",
        "image": col2_1
    },
    {
        "name": "",
        "bio": "",
        "image": col2_2
    },
    {
        "name": "",
        "bio": "",
        "image": col2_3
    },
    {
        "name": "",
        "bio": "",
        "image": col2_4
    },
    {
        "name": "",
        "bio": "",
        "image": col2_5
    },
    {
        "name": "",
        "bio": "",
        "image": col2_6
    },
    {
        "name": "",
        "bio": "",
        "image": col3_1
    },
    {
        "name": "",
        "bio": "",
        "image": col3_2
    },
    {
        "name": "",
        "bio": "",
        "image": col3_3
    },
    {
        "name": "",
        "bio": "",
        "image": col3_4
    },
    {
        "name": "",
        "bio": "",
        "image": col3_5
    },
    {
        "name": "",
        "bio": "",
        "image": col3_6
    },
    {
        "name": "",
        "bio": "",
        "image": col4_1
    },
    {
        "name": "",
        "bio": "",
        "image": col4_2
    },
    {
        "name": "",
        "bio": "",
        "image": col4_3
    },
    {
        "name": "",
        "bio": "",
        "image": col4_4
    },
    {
        "name": "",
        "bio": "",
        "image": col4_5
    },
    {
        "name": "",
        "bio": "",
        "image": col4_6
    },
    {
        "name": "",
        "bio": "",
        "image": col4_7
    },
    {
        "name": "",
        "bio": "",
        "image": col4_8
    }
];

const lifeEvents = [
    {
        "year": "2021",
        "title": "Think less feel more.",
        "desc": "Solo exhibition. Five year archive of Silkscreen printing, Serigraphy Virtual art installation, clay art. Mari Ataneli Zurab Tsereteli Museum of modern art (Moma Tbilis)"
    },
    {
        "year": "2020",
        "title": "Beyond",
        "desc": "Zurab Tsereteli Museum of modern art (MomaTbilisi)."
    },
    {
        "year": "2020",
        "title": "Beyond",
        "desc": "Reexhibition. Handmade printing festival Lifenstyle. Cas Batumi (Contemporary artBatumi)."
    },
    {
        "year": "2019",
        "title": "Soul",
        "desc": "Photo installation Mari Ataneli. Video installation Kristine Tusiashvili. Collaborative project Handmade printing festival Lifenstyle & Riia Keburia Foundation.\n"
    },
    {
        "year": "2019",
        "title": "Soul",
        "desc": "Baku Biennale"
    },
    {
        "year": "2018",
        "title": "Sniff art instead of cocs.",
        "desc": "Solo exhibition. Collaborative project Lifenstyle & Ria Keburia Gallery."
    },
    {
        "year": "2017",
        "title": "Making of",
        "desc": "Solo exhibition. Tbilisi, Georgia"
    },
    {
        "year": "2016",
        "title": "Fleur du Male",
        "desc": "Solo exhibition. Photo installation. Vernissage Gallery, Tbilisi, Georgia"
    },
    {
        "year": "2015",
        "title": "Take care of your body, it`s only space you have to live in.",
        "desc": "Solo exhibition. Photo installation."
    },
]

export default function Mari() {
    let navigate = useNavigate();
    const [currentPiece, setCurrentPiece] = useState(0);
    const [currentPiece2, setCurrentPiece2] = useState(0);
    const [currentPiece3, setCurrentPiece3] = useState(0);
    const [currentPiece4, setCurrentPiece4] = useState(0);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetToShow, setAssetToShow] = useState('');
    return (
        <Page>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Mari Ataneli
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            "For me no greater inspiration than life and to live. The art of creation is the continuation
                            of
                            my soul and everything life brings to me each and every fragment of a second can become a
                            part of this breathtaking process. Visual storytelling is my passion. I feel like , that
                            photography is the psychedelic adventure , the gate of self discovering, transformation ,
                            celebration of self love, and blissful state, the path of beyond physical communication,
                            touch
                            without touch (from me, through my lens to all alive being). Photography is absolutely
                            unique tool and invaluable gift for connection , sharing and collecting the moments what
                            will
                            never come back." - M. Ataneli
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{py: 8}} maxWidth="lg">
                    <Typography
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Works
                    </Typography>
                    <Grid className='artist-card' md={6} xs={12} style={{
                        margin: '0 auto',
                        width: '90%',
                        maxWidth: isMobile ? '90%' : '70%',
                        height: isMobile ? '55vh' : ''
                    }}>
                        <Carousel onSelect={(s) => {
                            setCurrentPiece(s)
                        }}>
                            {cards.map(item =>
                                <Carousel.Item onClick={() => {
                                    setShowAssetModal(true)
                                    setAssetToShow(cards[currentPiece])
                                }}>
                                    <div style={{backgroundColor: 'lightgray'}}>
                                        <img
                                            style={{cursor: 'pointer', objectFit: 'contain'}}
                                            className="artist-card-image"
                                            src={item.image}
                                            alt={`${item.title} art`}
                                        />
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className='artist-card-info' style={{height: isMobile ? '10vh' : ''}}>
                            <Typography variant="p" component="p" style={{color: 'white'}}>
                                {cards[currentPiece].name}
                            </Typography>
                            {cards[currentPiece].showLink &&
                                <Button
                                    style={{fontFamily: 'Comfortaa'}}
                                    variant="text" onClick={() => navigate(cards[currentPiece].link)}>View In
                                    Market</Button>
                            }
                        </div>
                    </Grid>
                    <Typography
                        style={{paddingTop: '24px'}}
                        component="h3"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Exhibits
                    </Typography>
                    <Timeline position="alternate">
                        {lifeEvents.map(event =>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {event.year}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <strong>{event.title}</strong> {event.desc}
                                </TimelineContent>
                            </TimelineItem>
                        )}
                    </Timeline>
                </Container>
            </main>
            <AssetViewModal open={showAssetModal} handleClose={() => setShowAssetModal(false)} asset={assetToShow.image}
                            assetName={assetToShow.name}/>
        </Page>
    );
}
//export default About;