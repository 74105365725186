import React, {useEffect, useState, useMemo} from 'react'
import {useWeb3} from '@3rdweb/hooks'
import {client} from '../../lib/sanityClient'
import {useParams} from 'react-router-dom';

import Page from '../../components/Page'
import {alchemy} from "../../lib/alchemy";
import {marketplace} from "../../lib/marketplace";

import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import CardActions from '@mui/material/CardActions';
import PurchaseButton from "../../components/NFT/PurchaseButton";
import ListingButton from "../../components/NFT/ListingButton";
import CancelButton from "../../components/NFT/CancelButton";
import {getListingItem} from "../../lib/utils/getListingItem";
import InfoAccordion from "../../components/NFT/InfoAccordion";
import AssetViewModal from "../../components/AssetViewModal";

import {ThirdwebSDK as ThirdwebSDKNightly} from "@thirdweb-dev/sdk";
import {mode} from "../../lib/constants";

const NFTPage = () => {
    const {provider, address} = useWeb3()
    const collectionId = useParams().id || ''
    const itemID = useParams().itemID || ''
    const [collection, setCollection] = useState({})
    const [selectedNFT, setNft] = useState(null)
    const [listings, setListings] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingButtons, setLoadingButtons] = useState(true)
    const [forceRefresh, setForceRefresh] = useState(true)
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetToShow, setAssetToShow] = useState('');

    const [isListed, setIsListed] = useState(false)
    const [listingInfo, setListingInfo] = useState({})
    const [price, setPrice] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingButtons(false)
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    //

    const nftContract = useMemo(() => {
        const sdk = new ThirdwebSDKNightly(alchemy[mode].httpKey);
        return sdk.getNFTCollection(collectionId);
    }, [])

    useEffect(() => {
        if (!nftContract) {
            return
        }
        ;(async () => {
            const nfts = await nftContract.getAll();
            const nftsTemp = [];
            nfts.map(r => {
                const newNFT = r.metadata;
                newNFT.owner = r.owner;
                nftsTemp.push(newNFT)
            })
            const selectedNftItem = nftsTemp.find((nft) => `${parseInt(nft.id, 16)}` === itemID)
            setNft(selectedNftItem)
            setLoading(false)
        })()
    }, [nftContract])

    const marketPlaceModule = useMemo(() => {
        if (!provider) return
        const sdk = new ThirdwebSDKNightly(
            provider.getSigner()
        )
        return sdk.getMarketplace(marketplace[mode].contract);
    }, [provider])

    const getListings = async () => {
        const newListings = await marketPlaceModule.getAllListings();
        setListings([])
        await setListings(newListings);
        const newForce = !forceRefresh;
        setForceRefresh(newForce)
    }

    // get all listings in the collection
    useEffect(() => {
        if (!marketPlaceModule) return
            ;
        (async () => {
            setListings(await marketPlaceModule.getAllListings())
        })()
    }, [marketPlaceModule])

    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "marketItems" && contractAddress == "${collectionId}" ] {
      "imageUrl": profileImage.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      volumeTraded,
      createdBy,
      _id,
      contractAddress,
      "creator": createdBy->userName,
      title, floorPrice,
      "allOwners": owners[]->,
      description
    }`

        const collectionData = await sanityClient.fetch(query)

        // the query returns 1 object inside of an array
        await setCollection(collectionData[0])
    }

    useEffect(() => {
        fetchCollectionData()
    }, [collectionId])

    useEffect(() => {
        const listing = getListingItem(listings, selectedNFT, collection);
        if (Boolean(listing)) {
            setIsListed(true)
            setPrice(listing.buyoutCurrencyValuePerToken.displayValue)
            setListingInfo(listing)
        }
    }, [listings, selectedNFT, forceRefresh]);

    return (
        <Page>
            {loading ? <div style={{width: '100%', textAlign: 'center'}}><CircularProgress/></div> :
                <>
                    {collection && selectedNFT ? <>
                            <div style={{margin: '24px'}}>
                                <Grid container spacing={2}>
                                    <Grid item lg={4} xs={12}>
                                        <div style={{}}>
                                            <img
                                                onClick={() => {
                                                    setAssetToShow({
                                                        name: selectedNFT.name,
                                                        image: selectedNFT.image.replace('cloudflare-ipfs.com', 'ipfs.thirdweb.com')
                                                    })
                                                    setShowAssetModal(true)
                                                }
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '100%', objectFit: 'cover', maxHeight: '50vh',
                                                    borderRadius: '4px',
                                                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                                }}
                                                src={selectedNFT.image.replace('cloudflare-ipfs.com', 'ipfs.thirdweb.com')}
                                                alt="banner"
                                            />
                                        </div>

                                    </Grid>
                                    <Grid item lg={8} xs={12}>
                                        <Stack spacing={4}>
                                            <Card variant='outlined'>
                                                <CardContent>
                                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                                        {collection.title}
                                                    </Typography>
                                                    <Typography variant="h5" component="div">
                                                        {selectedNFT.name}
                                                    </Typography>
                                                    {isListed &&
                                                        <Typography variant="body">
                                                            Price: {price} ETH
                                                        </Typography>
                                                    }
                                                </CardContent>
                                                <CardActions>
                                                    {!loadingButtons &&
                                                        <>
                                                            {isListed && address && address !== listingInfo.sellerAddress &&
                                                                <PurchaseButton
                                                                    isListed={isListed}
                                                                    selectedNft={selectedNFT}
                                                                    listings={listings}
                                                                    marketPlaceModule={marketPlaceModule}
                                                                    collection={collection}
                                                                />
                                                            }
                                                            {isListed && address && address === listingInfo.sellerAddress &&
                                                                <CancelButton
                                                                    isListed={isListed}
                                                                    selectedNft={selectedNFT}
                                                                    listings={listings}
                                                                    marketPlaceModule={marketPlaceModule}
                                                                    getListings={getListings}
                                                                    collection={collection}
                                                                />
                                                            }
                                                            {!isListed && address && address === selectedNFT.owner &&
                                                                <ListingButton
                                                                    isListed={isListed}
                                                                    selectedNft={selectedNFT}
                                                                    listings={listings}
                                                                    marketPlaceModule={marketPlaceModule}
                                                                    nftModule={nftContract}
                                                                    contractAddress={collectionId}
                                                                    getListings={getListings}/>
                                                            }
                                                            {isListed && !address &&
                                                                <Typography variant="body1">
                                                                    Connect your wallet to purchase this item
                                                                </Typography>
                                                            }
                                                        </>
                                                    }
                                                </CardActions>
                                            </Card>
                                            <Card variant='outlined'>
                                                <CardContent>
                                                    <InfoAccordion collection={collection} nft={selectedNFT}/>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                        </> :
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Item not found
                        </Typography>
                    }
                </>
            }
            <AssetViewModal open={showAssetModal} handleClose={() => setShowAssetModal(false)} asset={assetToShow.image}
                            assetName={assetToShow.name}/>
        </Page>
    )
}

export default NFTPage