import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../components/Page';
import Accordion from 'react-bootstrap/Accordion';
import {useNavigate} from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export default function FAQ() {
    let navigate = useNavigate();

    const handleLocationClick = (location) => {
        navigate(location)
    }

    const faqs = [
        {
            title: "Are you accepting new artists?",
            content: <>
                Yes! We are always looking for new talent to add to our ever growing community.
                <br/>
                Please reach out on our <Link onClick={() => handleLocationClick('/contact')}
                                              style={{cursor: 'pointer'}}>contact</Link> page.
            </>
        },
        {
            title: "How do your prices work?",
            content: "We work directly our artists to decide the price and quanitity of each initial offering. " +
                "After that it is up to the owners of each piece to decide their listing prices."
        },
        {
            title: "Refund Policy",
            content: "Due to the nature of NFTs all sales are final."
        },
        {
            title: "What is an NFT?",
            content: "NFT stands for non-fungible token. At the most basic level, a non-fungible token is a unique, verifiable digital asset that can be " +
                "exchanged between a creator and a buyer. The one-of-a-kind characteristic is important in distinguishing NFTs from other digital assets. " +
                "One NFT is not interchangeable with another NFT. Unlike a dollar bill or a share of stock which is interchangeable with each other."
        },
        {
            title: "What makes an NFT valuable?",
            content: "The value of an NFT comes from the property it represents, which is generally something that exists in the digital world like an original" +
                " piece of art or digital memorabilia. The NFT itself doesn’t necessarily contain the digital property, but points to its location on the blockchain." +
                " Like a concert ticket or a deed to a physical property, an NFT reflects the value of the thing it represents. "
        },
        {
            title: "What’s the connection between NFTs and cryptocurrency?",
            content: "NFTs aren’t cryptocurrencies, but they are built using technology similar to Ethereum and Bitcoin. Also, like cryptocurrencies, NFTs exist on a blockchain, " +
                "which verifies their unique identity and ownership. The blockchain also keeps a record of all the transactions connected to the NFT and the property it represents." +
                " Many NFTs are held on the Ethereum blockchain. "
        },
        {
            title: "Where do I keep my NFT?",
            content: "NFTs are kept in a Wallet, an NFT wallet doesn't work like a physical wallet. You don't actually store NFTs or cryptocurrency in your wallet. Instead, it provides access to the assets," +
                " which are held on the blockchain. It does so by providing a private key to that address, which allows the wallet owner to authorize transactions. " +
                "If you have the private key, you own anything at that address. Before you purchase an NFT, you will need to create your wallet.",
            links: [
                {
                    title: 'We recommend using Metamask',
                    link: 'https://metamask.io/'
                }
            ]
        },
        {
            title: "Are NFTs the future of art and collectibles?",
            content: "Many artists, musicians, celebrities, and others find NFTs attractive because they offer a new and unique way to present and sell their works." +
                " Even things like GIFs, memes, and tweets have become NFTs. They can also provide artists an opportunity to program in continued royalties if it is sold" +
                " to a new owner. Many galleries also see the potential for reaching the new generation of collectors. " +
                "If you would like to take a deeper dive into NFTs and Cryptocurrencies; here are some helpful resources:  ",
            links: [
                {title: 'What are NFTs?', link: 'https://ethereum.org/en/nft/#what-are-nfts'},
                {
                    title: 'What is a cryptocurrency?',
                    link: 'https://www.forbes.com/advisor/investing/what-is-cryptocurrency'
                },
                {title: 'How do NFTs create value?', link: 'https://hbr.org/2021/11/how-nfts-create-value'},
            ]
        }
    ];

    return (
        <Page>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            FAQ
                        </Typography>
                    </Container>
                </Box>
                <div style={{width: '75%', margin: '0 auto'}}>
                    <Accordion flush>
                        {
                            faqs.map((item, key) =>
                                <Accordion.Item eventKey={`${key}`}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.content}
                                        {item.links && <>
                                            <List dense={true}>
                                                {item.links.map((link) =>
                                                    <ListItem key={`link-${link.link}`}>
                                                        <Link href={link.link} target={'_blank'}>{link.title}</Link>
                                                    </ListItem>,
                                                )}
                                            </List>
                                        </>}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                    </Accordion>
                </div>
            </main>
        </Page>
    );
}
//export default About;