import React from "react";
import { Parallax } from "react-parallax";
import Page from "../../components/Page";
import mural from '../../assets/mural.jpg'
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Button, Card, CardMedia, CardContent, CardActionArea} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import giovannisechi from '../../assets/artists/giovannisechi/Monitors.jpg'
import gela_Zautashvili  from '../../assets/artists/gela_zautashvili/Couples.jpg';
import mari from '../../assets/artists/mari/IMG_3908 - Copy - Copy.jpg';
import jemal_kukhalashvili from '../../assets/artists/jemal_kukhalashvili/casino.jpg';
import Link from '@mui/material/Link';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Carousel} from "react-bootstrap";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};
const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

const itemData = [
    {
        img: giovannisechi,
        title: 'Giovanni Sechi',
        link:"/artists/giovanni-sechi"
    },
    {
        img: gela_Zautashvili ,
        title: 'Gela Zautashvili ',
        link: '/artists/gela-zautashvili',
    },
    {
        img: mari,
        title: 'Mari',
        link: '/artists/mari'
    },
];

const HomePage = () => {
    let navigate = useNavigate();

    const handleLocationClick = (location) => {
        navigate(location)
    }

    return (
        <Page>
            <div style={styles}>
                <Carousel>
                    {itemData.map(item=>
                        <Carousel.Item>
                            <img
                                style={{maxHeight: '75vh'}}
                                className="d-block w-100"
                                src={item.img}
                                alt={`${item.title} art`}
                            />
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h2"
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Our Mission
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Our mission is to showcase existing artists and to develop emerging artists in the form of NFT’s.
                            We see NFT’s as the next frontier for the art world and want to bring our years of experience in the traditional art market into the world of NFT’s.
                        </Typography>
                    </Container>
                </Box>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Box sx={{ flexGrow: 1, margin: '0 auto', width: '75%' }}>
                        <Typography
                            component="h2"
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Our Artists
                        </Typography>
                        <Grid container spacing={2}>
                            {itemData.map((item) => (
                                <Grid item xs={12} sm={6} md={4} key={item.img} style={{margin: 'auto'}}>
                                    <Link underline='none' component={RouterLink} to={item.link}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image={item.img}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {item.title}
                                            </Typography>
                                        </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </div>
                <br/>
                <Parallax bgImage={mural} strength={-100}>
                    <div style={{ height: '100vh' }}>
                        <div style={insideStyles}>
                            <Button variant="text" onClick={()=>handleLocationClick('marketplace')}>VIEW OUR MARKET</Button>
                        </div>
                    </div>
                </Parallax>
            </div>
        </Page>
)}
export default HomePage
