import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Page from '../../components/Page'
import giovannisechi from '../../assets/artists/giovannisechi/Monitors.jpg'
import gela_Zautashvili  from '../../assets/artists/gela_zautashvili/Couples.jpg';
import mari from '../../assets/artists/mari/IMG_3908 - Copy - Copy.jpg';
import jemal_kukhalashvili from '../../assets/artists/jemal_kukhalashvili/casino.jpg';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link';

const cards = [
    {
        "name": "Giovanni Sechi",
        "title": "",
        "bio":"Giovanni's bio",
        "image":giovannisechi,
        "link": "/artists/giovanni-sechi"
    },
    {
        "name": "Gela Zautashvili",
        "title": "",
        "bio":"Gela's bio",
        "image":gela_Zautashvili ,
        "link": "/artists/gela-zautashvili"
    },
/*    {
        "name": "Jemal Kukhalashvili",
        "title": "",
        "bio":"Jemal's bio",
        "image":jemal_kukhalashvili,
        "link": "/artists/jemal-kukhalashvili"
    },*/
    {
        "name": "Mari",
        "title": "",
        "bio":"Mari's bio",
        "image":mari,
        "link": "/artists/mari"
    }
];

export default function Artists() {
    return (
        <Page>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Artists
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Our mission is to showcase existing artists and to develop emerging artists in the form of NFT’s.
                            We see NFT’s as the next frontier for the art world and want to bring our years of experience in the traditional art market into the world of NFT’s.
                            <br />
                            We are a boutique NFT gallery that has handpicked artists that we believe offer significant value in order
                            to help collectors make the right choice in the expansive new NFT market. We have brought together talents
                            in the industry by a team of experts from the US, Europe, and the UK. Our challenge is to present an international
                            mix of artists, both established and emerging talents, who we believe will have a strong impact on the digital art market.
                            The NFT market is thriving and we are here to guide you through this process.
                        </Typography>
                        {/*                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant="contained">Main call to action</Button>
                            <Button variant="outlined">Secondary action</Button>
                        </Stack>*/}
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="xl">
                    <Grid container spacing={4}>
                        {cards.map((card, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                <Link underline='none' component={RouterLink} to={card.link}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        image={card.image}
                                        alt="random"
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {card.name}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="h4">
                                            {card.title}
                                        </Typography>
                                        <Typography>
                                            {card.bio}
                                        </Typography>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
        </Page>
    );
}
//export default About;