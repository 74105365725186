import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {LoadingModal} from "../../LoadingModal";
import {getListingItem} from "../../../lib/utils/getListingItem";
import {ethers} from "ethers";

const CancelButton = (props) => {
    const {isListed, selectedNft, listings, marketPlaceModule, getListings, collection} = props;
    const [selectedMarketNft, setSelectedMarketNft] = useState()
    const [enableButton, setEnableButton] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        if (!listings || isListed === 'false') return
            ;
        (async () => {
            const listing = getListingItem(listings, selectedNft, collection);
            setSelectedMarketNft(listing)
        })()
    }, [selectedNft, listings, isListed])

    useEffect(() => {
        if (!selectedMarketNft || !selectedNft) return

        setEnableButton(true)
    }, [selectedMarketNft, selectedNft])

    const cancelItem = async () => {
        setShowLoading(true)
        const listingID = ethers.BigNumber.from(selectedMarketNft.id);
        await marketPlaceModule
            .direct.cancelListing(listingID)
            .then(() => {
                getListings()
                setShowLoading(false);
                window.location.reload();
            })
            .catch((error) => {
                setShowLoading(false);
                console.error(error)
                if (error.code === 'INSUFFICIENT_FUNDS') {
                    alert('Insufficient funds')
                }
            })
    }
    return <><Button variant="contained" onClick={enableButton ? cancelItem : null}>Cancel Listing</Button>
        <LoadingModal
        open={showLoading}/>
    </>
}

export default CancelButton;